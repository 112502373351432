import { DbsPerson } from "@me-interfaces";

export interface AppSessionDetails {
	person: DbsPerson,
	mostRecentAppSessionId: number,
	sessionsIn30Days: number,
	timeActiveIn30Days: number,
	elapsed: number,
	ago: number,
	lastUseUTC: number,
	browsers: string | undefined,
}