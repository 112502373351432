import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccJudgement extends Dba {

	readonly _concept: DbConceptName.AccJudgement,

	accJudgeId: number,
	accTeamId: number,
	answersId: number,
	polishedFeedback: string,
	polishedFeedbackSkipped: boolean,
}


export interface DbaAccJudgement extends Immutable<MutableDbaAccJudgement> { }