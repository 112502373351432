import { DbConceptName, Dbs, Immutable, Person, PitchContest } from '@me-interfaces';


interface MutableDbsPicJudge extends Dbs {

	readonly _concept: DbConceptName.PicJudge,

	picJudgeId: number,
	personId: number,
	picId: number,
	judgedPicTeamIds: number[],
	updatedUTC: number,
	updatedByPersonId: number,
}

interface MutablePicJudge extends MutableDbsPicJudge {
	person: Person,
	pitchContest: PitchContest,
	updatedByPersonName: string,
}


export interface DbsPicJudge extends Immutable<MutableDbsPicJudge> { }
export interface PicJudge extends Immutable<MutablePicJudge> { }