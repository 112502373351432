import { DbsPerson } from "@me-interfaces";

export interface PendingVolunteer {
	volunteerApplicationId: number,
	siteId: number,
	createdUTC: number,
	languageId: number,
	applicant: DbsPerson,
	daysSinceApplying: string,
	roles: string,
}