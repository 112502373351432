import { Application, Company, DbConceptName, Dbs, DbsPerson, DbsPicTeamMember, ExplorablePackage, Immutable, PicPresentType, PitchContest } from "@me-interfaces";


interface MutableDbsPicTeam extends Dbs {

	readonly _concept: DbConceptName.PicTeam,

	readonly picTeamId: number,
	readonly picId: number,
	readonly applicationId: number,
	readonly presentType: PicPresentType,
	readonly attended: boolean,
	readonly notes: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}

interface MutablePicTeam extends MutableDbsPicTeam {
	//
	// Upward facing references (singletons, db, or packages) no arrays
	//
	pitchContest: PitchContest,
	application: Application,
	company: Company,

	//
	// Downward facing references (singletons or db only) arrays allowed
	//
	updatedByPersonName: string,
	members: { member: DbsPicTeamMember, person: DbsPerson }[],
	readonly awards: { awardId: number, value: number }[],
	readonly awardedValue: number,
}


export interface DbsPicTeam extends Immutable<MutableDbsPicTeam> { }
export interface PicTeam extends ExplorablePackage<DbConceptName.PicTeam>, Immutable<MutablePicTeam> { }