export const enum TagPrefixId {
	// General
	Lang = 5,
	EforAll = 6,
	HQ = 7,
	Eforever = 17,
	Specialty = 13,

	// Sites
	Site_Lola = 1,
	Site_Lalo = 2,
	Site_Soco = 3,
	Site_Lynn = 4,
	Site_Cape = 8,
	Site_Hlyk = 9,
	Site_Lgmt = 10,
	Site_Rxby = 11,
	Site_Berk = 12,
	Site_Pitt = 15,
	Site_Gwor = 16,
	Site_Nwst = 18,
	Site_Buff = 19,
}