import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccMatchAssessment extends Dba {

	readonly _concept: DbConceptName.AccMatchAssessment,

	accTeamId: number,
	/** personId of the matchable mentor */
	personId: number,
	t2m: number,
	m2t: number,
}


export interface DbaAccMatchAssessment extends Immutable<MutableDbaAccMatchAssessment> { }