import { DbsAward, DbsCompany, DbsEvent } from "@me-interfaces";

export interface ProgramEventAwards {
	event: DbsEvent,
	awards: DbsAward[],
	teams: ProgramEventAwardTeam[],
}


export interface ProgramEventAwardTeam {
	accTeamId?: number,
	picTeamId?: number,
	company: DbsCompany,
}


export interface Old_Award extends DbsAward {
	company: DbsCompany,
	awardName: string,// create types for each
	decidingRole: string,
	awardKind: string,
}