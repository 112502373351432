import { LabelKey } from '@me-interfaces';
import { State } from "@progress/kendo-data-query";
import { GridAction } from './grid-action';
import { GridColumn } from './grid-column';
import { DialogGridSize, InlineGridSize, PageGridSize, PageTabsMainTabGridSize } from './grid-size';

export type GRID_EXPERIENCE =
	'none' |
	'ACC_SURVEY_RESPONSE_SESSION' |
	'ACC_SURVEY_RESPONSE_SPECIALIST' |
	'ACC_SURVEY_RESPONSE_TOPIC' |
	'ACC' |
	'ACCTEAM' |
	'APPLICATION' |
	'AWARD' |
	'COMPANY' |
	'EVENT' |
	'PERSON' |
	'PIC' |
	'PICTEAM' |
	'REGION' |
	'SITE' |
	'ZIP';


/**
 * The configuration of a kendo grid wrapped in a me-grid component.
 */
export interface GridSetup<RowT> {

	/**
	 * Use a row base to automatically get a standard set of columns and grid actions. If the data in a row
	 * is essentially a 'subclass' of one of these data types then use this to ensure the user has a standard
	 * experience and to reduce the amount of custom columns and grid actions needed.
	 * 
	 * Each row must have a corresponding identifier field. For example, a rowBase of 'PERSON' expects there
	 * to be a property of 'personId' on each row object.
	 */
	experience?: GRID_EXPERIENCE | undefined,


	/**
	 * The descriptive name of a single row.
	 */
	rowSingularName: string | LabelKey,


	/**
	 * The descriptive name of a set of rows
	 */
	rowPluralName: string | LabelKey,


	/**
	 * The name of the property on each row object that can be read to get
	 * a unique identifier for that row.  The property should reference a
	 * unique string or number value.
	 */
	rowKey: string,


	/**
	 * A unique key that is used to store the current grid state, including
	 * sorting, filtering, grouping, and current scroll position. This state
	 * is stored in the sessionStorage so if the user leaves a page and then
	 * comes back, the grid will stay as they left it, even of the grid was
	 * reconsituted.
	 */
	stateKey: string,


	/**
	 * The columns displayed on the grid.  If a rowBase is provided, then an initial set
	 * of collumns will be added automatically. If a column already exists with the a
	 * a same field then it will replace the prior column with new config.
	 */
	columnsToAdd?: GridColumn<RowT>[],


	/**
	 * Define changes to existing experience columns. This can be used, for example, to
	 * change a column that is hidden by default to be visible, or to change the header.
	 */
	columnsToAlter?: Partial<GridColumn<RowT>>[],


	/**
	 * The columns displayed on the grid
	 */
	actions?: GridAction[],


	/**
	 * Optionally add custom logic to set or unset the
	 * GridAction enabled property given the row(s) selected.
	 * This callback is only called if there is at least one
	 * selected row.
	 * 
	 * Default: (action, rows) => action.enabled = true;
	 */
	actionEnabler?: (action: GridAction, /** There will always be at least one selected row. */ selectedRows: RowT[]) => boolean,



	/**
	 * Optionally add custom logic to update notes 
	 * property given the selected row.
	 * This callback is only called if there is just one
	 * selected row.
	 */
	//launchAndSaveNoteAction?: (text: string, id: number, header: string, readonly: boolean) => Promise<{ success: boolean }>,

	/**
	 * Defines how the grid should be sized.
	 */
	size: PageGridSize | PageTabsMainTabGridSize | InlineGridSize | DialogGridSize,


	/**
	 * If true, then a button will be rendered that lets the user add a row.
	 * When clicked, a gridAction event will be raised with a key of
	 * ADDROW_GRID_ACTION_KEY. The default is false.
	 */
	canAdd: boolean,

	/**
	 * If true, then a button will be rendered that lets the user download the list.
	 * When clicked, a gridAction event will be raised with a key of
	 * DOWNLOAD_GRID_ACTION_KEY. The default is true.
	 */
	canDownload: boolean,

	/**
	 * Default: uses the plural name
	 * Alternately send the download file name to be used.
	 */
	downloadFileName?: string,


	/**
	 * If true, then a button will be rendered that lets the user refresh the list.
	 * When clicked, a gridAction event will be raised with a key of
	 * REFRESH_GRID_ACTION_KEY. The default is false.
	 */
	canRefresh: boolean,


	/**
	 * If true, then demographic columns will be automatically added to Application
	 * base grids.
	 */
	showDemographics?: boolean,


	/**
	 * Whether the user can select multiple rows or just one. The default is
	 * false.
	 */
	multiselect?: boolean,


	/**
	 * Optional. Default='show'.
	 * 
	 * If 'show', there will be an area on top of the grid where column headers can be dropped for grouping and
	 * the right of that will contain various buttons (e.g. Add, Download,Actions Menu, etc.).
	 * 
	 * If 'showWithoutInstructions' then the 'Drag a column...' message will not be shown. Use this when the grid
	 * will not be wide enough.
	 * 
	 * If 'hide' then the grid will not be groupable and there will be no group area above the rows. The top-right
	 * action buttons will not be visible either. However, grid actions will always be available by right-clicking
	 * a row. 
	 */
	groupArea?: 'show' | 'showWithoutInstructions' | 'hide',


	/**
	 * Optionally preconfigure any of the grid state values,
	 * such as sorting, grouping, and filtering,
	 */
	initialState?: State;

}