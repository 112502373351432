export const enum QuestionTypeId {
	TextLine = 1,
	TextMulti = 2,
	Text1000 = 3,
	Rating_5 = 4,
	Yes_No = 5,
	Can_Attend_Meetings = 6,
	Text_300 = 7,
	EntrepreneurFeedback = 8,
	Ent_Feedback_Eforall = 9,
	EforAll_Feedback = 10,
}