import { AccApplicantDetails } from '@me-interfaces';

export interface AccApplicantSelectionDetails extends AccApplicantDetails {
	reading?: {
		ratings: [number, number, number, number],
		score: number,
	},
	interview?: {
		ratings: [number, number, number, number],
		score: number,
	},
}