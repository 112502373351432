import { ApplicationOverview, FieldsAndValues } from '@me-interfaces';
import { ApplicationFieldsForTeam } from './application-fields-for-team';

export interface ApplicationFields {
	overview: ApplicationOverview,
	business: FieldsAndValues,
	applicant: FieldsAndValues,
	team: ApplicationFieldsForTeam,
	questions: FieldsAndValues,
	staffOnlyQuestions: FieldsAndValues,
}