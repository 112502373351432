import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from '@me-interfaces';

export type SiteV2SubArea =
	'config' |
	'data';


export interface SiteAreaAccess extends AppAreaAccess {
	root: AppAreaAccessValue,
	sub: {
		config: AppSubAreaAccessValue,
		data: AppSubAreaAccessValue,
	}
}