export const enum PrefixId {
	Mr = 1,
	Ms = 2,
	Mrs = 3,
	Dr = 4,
	Prof = 5,
	Rabbi = 6,
	Reverend = 7,
	Senator = 8,
	Governor = 9,
	Mayor = 10,
}