import { DbsAccelerator, DbsCompany, DbsPerson } from "@me-interfaces";

export interface SiteAreaAccelerator {
    accelerator: DbsAccelerator,
    applicationCounts: {
        [applicationStatusId: number]: number,
    },
    teams: {
        accTeamId: number,
        dropOutWeek: number | null,
        company: DbsCompany,
        reportImportance: string,
        lastYearUTC: number | undefined,
        thisYearUTC: number | undefined,
        entrepreneurs: DbsPerson[],
        admins: DbsPerson[],
    }[]
}