import { CompanyTypeId } from '@me-interfaces';

/**
 * @deprecated Use domain data instead
 */
export interface Old_CompanyType {
	companyTypeId?: CompanyTypeId,
	name?: string,
	isNonprofit: boolean,
	isFormed: boolean,
}