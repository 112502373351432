import { Races } from "@me-interfaces";

export interface PersonDemographics {
	gender: 'M' | 'F' | 'N' | 'O' | undefined,
	genderOther: string | undefined,
	races: Races | number | undefined,
	raceOther: string | undefined,
	hispanic: 'Y' | 'N' | undefined,
	birthYear: number | undefined,
	birthDay: number | undefined,
	veteran: 'Y' | 'N' | undefined,
	immigrant: 'Y' | 'N' | undefined,
	nativeEnglish: 'Y' | 'N' | undefined,
	nativeSpanish: 'Y' | 'N' | undefined,
	handicapped: boolean,
	hasOptOutDemographics: boolean,
	isDeceased: boolean,
}