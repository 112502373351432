/**
 * This type should match the values in the database. To rebuild this list manually execute this SQL:
 * SELECT path FROM adminRoute ORDER BY path
 */
export type AdminRoute =
	'/' |
	'/all/active-programs' |
	'/all/active-events' |
	'/all/integration/mailchimp' |
	'/all/pending-volunteers' |
	'/config' |
	'/dashboard' |
	'/data' |
	'/data/alumni-nagging' |
	'/data/hear-report' |
	'/people' |
	'/people/roles' |
	'/people/volunteers' |
	'/people/volunteers/:volunteerApplicationId' |
	'/programs' |
	'/programs/accelerators' |
	'/programs/accelerators/:accId' |
	'/programs/accelerators/:accId/applications' |
	'/programs/accelerators/:accId/applications/:applicationId' |
	'/programs/accelerators/:accId/applications/:applicationId/edit' |
	'/programs/accelerators/:accId/applications/:applicationId/view' |
	'/programs/accelerators/:accId/applications/add' |
	'/programs/accelerators/:accId/cohort' |
	'/programs/accelerators/:accId/cohort/:accTeamId' |
	'/programs/accelerators/:accId/events' |
	'/programs/accelerators/:accId/events/awards-ceremony' |
	'/programs/accelerators/:accId/events/final-pitch' |
	'/programs/accelerators/:accId/events/final-pitch/assessments' |
	'/programs/accelerators/:accId/events/final-pitch/editor' |
	'/programs/accelerators/:accId/events/final-pitch/events' |
	'/programs/accelerators/:accId/events/interviewing' |
	'/programs/accelerators/:accId/events/interviewing/assessments' |
	'/programs/accelerators/:accId/events/interviewing/editor' |
	'/programs/accelerators/:accId/events/interviewing/events' |
	'/programs/accelerators/:accId/events/mentor-matching' |
	'/programs/accelerators/:accId/events/mentor-matching/assessments' |
	'/programs/accelerators/:accId/events/mentor-matching/editor' |
	'/programs/accelerators/:accId/events/mentor-matching/events' |
	'/programs/accelerators/:accId/events/mentor-matching/heatmap' |
	'/programs/accelerators/:accId/events/mentor-matching/matching' |
	'/programs/accelerators/:accId/events/progress-pitch' |
	'/programs/accelerators/:accId/events/quarterlies' |
	'/programs/accelerators/:accId/events/reading' |
	'/programs/accelerators/:accId/events/reading/assessments' |
	'/programs/accelerators/:accId/events/reading/editor' |
	'/programs/accelerators/:accId/events/reading/event' |
	'/programs/accelerators/:accId/events/winner-selection' |
	'/programs/accelerators/:accId/feedback' |
	'/programs/accelerators/:accId/feedback/entrepreneur' |
	'/programs/accelerators/:accId/mentors' |
	'/programs/accelerators/:accId/overview' |
	'/programs/accelerators/:accId/tasks' |
	'/programs/accelerators/add' |
	'/programs/pitch-contests' |
	'/programs/pitch-contests/:picId' |
	'/programs/pitch-contests/:picId/applications' |
	'/programs/pitch-contests/:picId/applications/:applicationId' |
	'/programs/pitch-contests/:picId/applications/:applicationId/edit' |
	'/programs/pitch-contests/:picId/applications/:applicationId/view' |
	'/programs/pitch-contests/:picId/applications/add' |
	'/programs/pitch-contests/:picId/awards' |
	'/programs/pitch-contests/:picId/events' |
	'/programs/pitch-contests/:picId/events/contest' |
	'/programs/pitch-contests/:picId/events/contest/assessments' |
	'/programs/pitch-contests/:picId/events/contest/editor' |
	'/programs/pitch-contests/:picId/events/contest/event' |
	'/programs/pitch-contests/:picId/events/reading' |
	'/programs/pitch-contests/:picId/events/reading/assessments' |
	'/programs/pitch-contests/:picId/events/reading/editor' |
	'/programs/pitch-contests/:picId/events/reading/event' |
	'/programs/pitch-contests/:picId/feedback' |
	'/programs/pitch-contests/:picId/feedback/entrepreneur' |
	'/programs/pitch-contests/:picId/overview' |
	'/programs/pitch-contests/:picId/participants' |
	'/programs/pitch-contests/:picId/participants/:picTeamId' |
	'/programs/pitch-contests/add' |
	'/venues';