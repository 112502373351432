import { Answer, Old_MinimalCompany, Old_MinimalContact, Question } from '@me-interfaces';

export type Assessments = [number, number, number, number];
export type Ratings = [number, number, number, number, number];
export type SortBy = 'assessments' | 'questions' | 'name' | 'scores';
export type SortOrder = 'pcq' | 'pqc' | 'cpq' | 'cqp' | 'qpc' | 'qcp';
export type SortOrderName = 'company' | 'person' | 'question';
export type AggregateBy = 'assessments' | 'questions' | 'grades' | 'scores';

export interface RatingLevel {
	person?: Old_MinimalContact,
	company?: Old_MinimalCompany,
	question?: Question,
	assessments: Assessments,
	ratings: Ratings,
	aScore: number, // assessments score
	rScore: number, // question ratings score
	score: number,
	tooltip: string,
	children?: RatingLevel[],
	answer?: Answer, // only used on the third level
}

// export interface RatingsData {
// 	person: Old_MinimalContact,
// 	ratings: {
// 		company: Old_MinimalCompany,
// 		rating?: number,
// 		answers: Answer[],
// 		event?: DbcEvent,
// 	}[]
// }

export interface FlatRatingsData {
	personList: Old_MinimalContact[],
	companyList: Old_MinimalCompany[],
	questionList: Question[],
	answers: { [key: string]: Answer },
	assessments: { [key: string]: Assessments }
}