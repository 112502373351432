import { SiteAdminAcceleratorCohortTeamMember } from "@me-interfaces";

export interface AccCohortTeam {
	accId: number,
	accTeamId: number,
	withdrawnUTC: number | undefined,
	teamDriveId: string | undefined,
	notes: string,
	droppedOut: number | undefined,
	applicantPersonId: number,
	companyId: number,
	applicationId: number,
	origIsNonProfit: boolean,
	nowIsNonProfit: boolean | undefined,
	origCompanyName: string,
	nowCompanyName: string | undefined,
	companyType: string | undefined,
	members: SiteAdminAcceleratorCohortTeamMember[],
	companyPositions: {
		personId: number,
		firstName: string,
		lastName: string,
		title: string,
	}[],
	remainingMentors: {
		personId: number,
		firstName: string,
		lastName: string,
		//hasCoachTag: boolean,
	}[],
}
