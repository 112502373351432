import { Old_CompanyDetails, ReportPersonDetails, ReportYearDetails, NetPromoterScore } from '@me-interfaces';

export interface AlumReport {
	accTeamId: number,
	thisYear: {
		year: number,
		report?: ReportYearDetails,
	},
	lastYear: {
		year: number,
		report?: ReportYearDetails,
		needsActuals?: boolean,
	},
	netPromoter?: NetPromoterScore, // if present, the form should be displayed. There may or may not be a prior value.
	allYears: number[],
	minimalGiven: boolean,
	showAllFields: boolean,
}


export interface AccPeopleReport {
	entrepreneurs: ReportPersonDetails[],
	admins: ReportPersonDetails[],
	accName: string,
	companyName: string,
	companyId: number,
}