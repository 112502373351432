export interface Zip {
	readonly zipId: number,
	readonly code: string,	// same as zipId but as a five-digit string with leading zeros if needed
	readonly latitude: number,
	readonly longitude: number,
	readonly city: {
		readonly cityId: number,
		readonly name: string,
		readonly state: string,
	}
}