import { FieldContext } from './field-context';
import { FieldKind } from './field-kind';
import { FieldOptionsList } from './field-options-list';
import { FieldNumericFormat } from './field-numeric-format';
import { LabelKey } from '@me-interfaces';

/**
 * Represents a full description of what a field is and how
 * it should be rendered within the UI.
 */
export interface Field {

	/** Defines what will be changed in the db. The field just passes it along. */
	context: FieldContext,

	/** A unique id based on the context, used for referencing the value. The key is auto-calcuated */
	key?: string,

	/** Maps the function to the appropriate input type in the UI */
	kind: FieldKind,

	/**
	 * The text to display in the single-line label just above the input or in the question area,
	 * depending on whether labelIsQuestion is true. A value of type string will be displayed as-is,
	 * not translated. A value of type LabelKey will be translated.
	 */
	label: string | LabelKey,

	/**
	 * If true, the label value will be shown in an <me-label> at the top of the rendered field, and
	 * the regular single-line label will say 'Your Answer'. Default: false 
	 */
	labelIsQuestion?: boolean,

	/** If true then the user will be required to enter a value. Default: true */
	required?: boolean,

	/** If true, a '' value will be saved as null.  Default: true */
	saveEmptyStringAsNull?: boolean,

	/** If true, a zero value will be saved as null.  Default: false */
	saveZeroAsNull?: boolean,

	///
	/// Properties specific to FieldKind.Text and FieldKind.Textarea
	///

	/** For text and textarea field types, this defines the maximum count of characters that will be allowed. Default: 100 */
	maxLength?: number,

	/** For text and textarea field types, this defines the minimum count of characters that will be allowed. Default: 0 */
	minLength?: number,


	///
	/// Properties specific to FieldKind.Select
	///

	/** Defines the set of select options the user can choose from */
	fieldOptionsList?: FieldOptionsList,

	/** If an option has showOtherValueField as true, this defines what will be changed */
	otherValueContext?: FieldContext,

	/** A unique id based on the context, used for referencing the value. The key is auto-calcuated */
	otherValueKey?: string,


	///
	/// Properties specific to FieldKind.Numeric
	///

	/** For numeric field type, this defines the minimum value (integer) that the will be allowed. Default: 0 */
	minValue?: number,

	/** For numeric field type, this defines the maximum value (integer) that the will be allowed. */
	maxValue?: number,

	/** For numeric field type, this defines the format of the value that the will be allowed. Default: FieldNumericFormat.Decimal */
	numericFormat?: FieldNumericFormat,

	/** For numeric field type, this defines the decimal length of a float value that the will be allowed. Default: 0 */
	decimalLength?: number,
}