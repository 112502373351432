import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsPicTeamMember extends Dbs {

	readonly _concept: DbConceptName.PicTeamMember,

	readonly picTeamMemberId: number,
	readonly picTeamId: number,
	readonly personId: number,
	readonly notes: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsPicTeamMember extends Immutable<MutableDbsPicTeamMember> { }