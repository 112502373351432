import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccSessionTopic extends Dba {

	readonly _concept: DbConceptName.AccSessionTopic,

	accSessionTopicId: number,
	accSessionId: number,
	accTopicId: number,
}


export interface DbaAccSessionTopic extends Immutable<MutableDbaAccSessionTopic> { }