import { LanguageId, Old_MinimalContact, ProgramType, Old_SiteProgram } from '@me-interfaces';

/**
 * @deprecated Use the Program from the DataService instead
 */
export interface Old_Program {
	programId: number,
	programType: ProgramType,
	name: string,
	languageId: LanguageId,
	programDirector: Old_MinimalContact,
	sitePrograms: Old_SiteProgram[],
	createdUTC: number,
}