import { AccStageId, DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAccStage extends Dbd {

	readonly _concept: DbConceptName.AccStage,

	accStageId: AccStageId,
	name: string,
}


export interface DbdAccStage extends Immutable<MutableDbdAccStage> { }