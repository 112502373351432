import { Old_MinimalContact } from "@me-interfaces";

export interface AccApplicantDetails extends Old_MinimalContact {
	applicationId: number,
	demographicsGiven: string,
	city: string,
	zipId: number,
	company: string,
	status: string,
	applicationStatusId: number,
}