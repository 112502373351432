import { AccAreaData, AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, PersonCols } from '@me-grid';
import { AgreementTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface EntrepreneurRow {
	personId: number,
	companyName: string,
	mentors: string,
	handbookSigned: string,
	mediaSigned: string,
}

@Component({
	selector: 'acc-cohort-entrepreneurs-view-part',
	templateUrl: './acc-cohort-entrepreneurs-view.part.html',
})
export class AccCohortEntrepreneursViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows$: Observable<EntrepreneurRow[]> = of([]);
	data$: any;


	constructor(
		private accAreaService: AccAreaService,
		private pageService: AccCohortPageService,
		private ds: DataService,
		private util: UtilityService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();
		this.rows$ = this.accAreaService.data$.pipe(switchMap(data => this.buildRows(data)));
	}


	async buildRows(data: AccAreaData,): Promise<EntrepreneurRow[]> {

		if (!data) return [];

		const teams = data.team.teams;
		const entrepreneurs: EntrepreneurRow[] = [];
		const handbookVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.EntrepreneurHandbook)).map(version => version.agreementVersionId);
		const mediaConsentVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MediaConsent)).map(version => version.agreementVersionId);
		const signatures = await this.ds.admin.agreementSignature.getAllAsArray();
		const handbookSignatures = signatures.filter(signature => handbookVersionIds.includes(signature.agreementVersionId));
		const mediaSignatures = signatures.filter(signature => mediaConsentVersionIds.includes(signature.agreementVersionId));


		for (const accTeam of teams) {

			const mentors = accTeam.members
				.filter(m => ['C', 'M'].includes(m.member.role))
				.map(m => m.person._name)
				.sort()
				.join(', ');

			for (const member of accTeam.members) {

				if (member.member.role === 'E') {

					entrepreneurs.push({
						personId: member.member.personId,
						companyName: accTeam.company.name,
						mentors,
						handbookSigned: handbookSignatures.find(signature => signature.personId == member.member.personId) ? 'Signed' : '',
						mediaSigned: mediaSignatures.find(signature => signature.personId == member.member.personId) ? 'Signed' : '',

					});
				}
			}
		}
		return entrepreneurs;
	}

	private setupGrid(): GridSetup<EntrepreneurRow> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Entrepreneur",
			rowPluralName: "Entrepreneurs",
			rowKey: "personId",
			stateKey: "adm-acc-cohort-entrepreneurs-page",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				buildColumn('companyName', GridColumnConfig.companyName, { header: 'Business' }),
				buildColumn('mentors', GridColumnConfig.personFullNameMany, { header: 'Mentors' }),
				buildColumn('handbookSigned', GridColumnConfig.text, { header: 'Handbook', headerTooltip: 'Signed Entrepreneur Handbook' }),
				buildColumn('mediaSigned', GridColumnConfig.text, { header: 'Media Consent', headerTooltip: 'Signed Media Consent', hidden: true }),
			],
			columnsToAlter: [
				{ field: PersonCols.location, hidden: false },
			],
			initialState: {
				sort: [{ field: PersonCols.name, dir: 'asc' }],
			},
		};
	}

}
