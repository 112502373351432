import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAccSeason extends Dbd {

	readonly _concept: DbConceptName.AccSeason,
	
	accSeasonId: number,
	name: string,
}


export interface DbdAccSeason extends Immutable<MutableDbdAccSeason> { }