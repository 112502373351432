import { FieldKind } from '.';

export interface FieldKindAddress {
	
	/** Determines if the zipId is used */
	inUS: 'Y' | 'N',
	
	/** This is either the street address or full address depending on inUS */
	address?: string,

	/** The zip code as number, so no leading zeros. This is the primary key in the zip table */
	zipId?: number,

	/** This isn't entered in directly by user, but is stored here for display */
	cityAndState?: string,
}