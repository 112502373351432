import { DbsAccelerator, DbsPitchContest, DbcSiteIntern, PicPresentType, SiteAdminOverview, SiteVolunteerApplication } from '@me-interfaces';

export interface SiteAreaRawData_old {

	siteId: number,

	directors: {
		directorId: number,
		altDirectorId?: number,
	},

	offeredSitePrograms: {
		siteProgramId: number,
		managerId: number,
		altManagerId?: number,
		internIdsWithAccess: number[],
	}[],

	interns: {
		siteIntern: DbcSiteIntern,
		siteProgramId: number[]
	}[],

	personIdsWithSiteTags: number[],

	openVolunteerApplications: SiteVolunteerApplication[], //TODO: Refactor to use VolunteerRawData interface and return all.

	accelerators: {
		accelerator: DbsAccelerator,
		applicationCounts: {
			[applicationStatusId: number]: number,
		},
		teams: {
			accTeamId: number,
			dropOutWeek: number | null,
			companyId: number,
			reportImportance: string,
			lastYearUTC: number | undefined,
			thisYearUTC: number | undefined,
			entrepreneurIds: number[],
			adminIds: number[],
		}[]
	}[],

	pitchContests: {
		pitch: DbsPitchContest,
		applicationCounts: {
			[applicationStatusId: number]: number,
		},
		teams: {
			picTeamId: number,
			presentType: PicPresentType,
		}[]
	}[],

	overview: SiteAdminOverview,

	roledPersonIds: number[],
}