import { DbConceptName, Dbd, Immutable, SuffixId } from '@me-interfaces';


interface MutableDbdSuffix extends Dbd {

	readonly _concept: DbConceptName.Suffix,

	suffixId: SuffixId,
	name: string,
}


export interface DbdSuffix extends Immutable<MutableDbdSuffix> { }