import { AppAreaAccess, AppAreaMessage, AppAreaState, SingletonData } from "@me-interfaces";


/**
 * Every app area get and update action function must return an AppAreaFuncResponse
 * instance.
 */
export interface AppAreaFuncResponse {

	/**
	 * After a function is completed, the new state of that area along
	 * with the state of any other areas that may have changes will be
	 * returned. The client will apply each area state and indirectly
	 * trigger UI updates if the md4hash value has changed. 
	 */
	areaStates: AppAreaState<AppAreaAccess, unknown>[],


	/**
	 * After an action function returns, any message included will be
	 * displayed to the user with a temporary notification popup.
	 */
	message?: AppAreaMessage,


	/**
	 * Each call to a function includes a singletonsCacheUTC value which
	 * indicates when the client singletons were last cached. Singletons
	 * that were changed or created since that date will be returned and
	 * to be added to the client cache.
	 */
	updatedSingletons?: SingletonData,


	/**
	 * The newSingletonsCacheUTC value indicates which singletons that the
	 * client knows about after the client merges updatedSingletons into
	 * its cache.
	 */
	newSingletonsCacheUTC?: number,


	/**
	 * Set to true when the result came from a noop function
	 */
	isNoop?: boolean,


	/**
	 * If a new record was inserted into the database, return the id of the inserted row 
	 */
	insertId?: number,
}


export interface AppAreaApplyResponse {
	success: boolean,
	insertId?: number,
}