import { AppAreaAccess, AppAreaName, AppAreaMessage } from "@me-interfaces";


/**
 * Every call to an app area action function will return an instance
 * of an AppAreaFuncResponse which contains one or more area state
 * objects used to apply the latest internal state values to their
 * corresponding area service.
 */
export interface AppAreaState<ACCESS extends AppAreaAccess, RAW_DATA> {

	/**
	 * The unique name of the app area
	 */
	area: AppAreaName,


	/**
	 * An obect that defines what permissions the user has. 
	 */
	access: ACCESS,


	/**
	 * The raw data returned from the database. Common concepts,
	 * such as people and companies, should be added to singletons
	 * and the raw data should reference them by id. If the user
	 * does not have access, or the identfier is invalid, then the
	 * rawData value will be undefined. 
	 */
	rawData?: RAW_DATA,



	/**
	 * A hash of the stringified response from the backend which can
	 * be used to determine if the latest response is different than
	 * the prior one. Note that the hash is calculated on the client.
	 */
	md5Hash: string,


	/**
	 * Indicates that this response came from a Noop function
	 */
	isNoop?: boolean,
}

