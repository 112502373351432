import { Dbc } from "@me-interfaces";


export interface DbcZip extends Dbc {
	readonly zipId: number,
	readonly zipType: string,
	/** Same as zipId but as a five-digit string with leading zeros as needed */
	readonly code: string,	//Made by DbConcept class
	readonly latitude: number,
	readonly longitude: number,
	readonly cityId: number,
	readonly city: string, //Made by DbConcept class
	/** Formatted as "City, ST" */
	readonly cityAndState: string, //Made by DbConcept class by renaming the city.name column
	readonly state: string,
	readonly enSiteId: number,
	readonly esSiteId: number,
	readonly timeZoneId: number,
}