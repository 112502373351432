import { Dbc } from "@me-interfaces";


export interface DbcSiteIntern extends Dbc {
	readonly siteInternId: number,
	readonly siteId: number,
	readonly personId: number,
	readonly createdUTC: number,
	readonly canViewContacts: boolean,
	readonly canPrepareSiteContacts: boolean,
}