import { DbConceptName, Dbs, DbsPerson, Immutable, TagPrefixId } from '@me-interfaces';


interface MutableDbsTagPrefix extends Dbs {

	readonly _concept: DbConceptName.TagPrefix,

	readonly tagPrefixId: TagPrefixId,
	readonly prefix: string,
	readonly siteId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


interface MutableTagPrefix extends MutableDbsTagPrefix {
	updatedByPersonName: string,
}


export interface DbsTagPrefix extends Immutable<MutableDbsTagPrefix> { }
export interface TagPrefix extends Immutable<MutableTagPrefix> { }