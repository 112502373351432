/**
 * An optional message that can be returned from a function
 * that confirms to the user that their action was recognized.
 */
export interface AppAreaMessage {

	/** Notification will indicate whether the activity succeeded or not. */
	success: boolean,
	
	/** Text will be passed to the LabelsService. Prepend with a ':' to have it translated. */
	label: string,
}