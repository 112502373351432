import { AwardNameId, DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAwardName extends Dbd {

	readonly _concept: DbConceptName.AwardName,

	awardNameId: AwardNameId,
	name: string,
	order: number,
}


export interface DbdAwardName extends Immutable<MutableDbdAwardName> { }