import {
	DbaAccInterview,
	DbaAccInterviewerEvent,
	DbaAccJudgeEvent,
	DbaAccJudgement,
	DbaAccMatchAssessment,
	DbaAccMatchableMentor,
	DbaAccReading,
	DbaApplicationStatusLog,
	DbdAccLanguageSeason,
	DbdAccMeetingTimes,
	DbdAccSeason,
	DbdAccStage,
	DbdApplicationStatus,
	DbdAwardKind,
	DbdAwardName,
	DbdAwardNameDecidingRole,
	DbdAwardNameEventType,
	DbdCity,
	DbdCompanyType,
	DbdDecidingRole,
	DbdEventType,
	DbdIndustry,
	DbdLanguage,
	DbdNoteCategory,
	DbdPhoneType,
	DbdPicStage,
	DbdPrefix,
	DbdProgramType,
	DbdQuestionType,
	DbdQuestionsType,
	DbdState,
	DbdSuffix,
	DbdTopic,
	DbdTopicMaterial,
	DbdTopicMaterialType,
	DbdWebLinkType,
	DbsAccInterviewer,
	DbsAccJudge,
	DbsAccReader,
	DbsAccSessionSpecialistSurveyResponse,
	DbsAccSessionSurveyResponse,
	DbsAccSessionTopicSurveyResponse,
	DbsAccTeam,
	DbsAccTeamMember,
	DbsAccelerator,
	DbsAgreementSignature,
	DbsAgreementType,
	DbsApplication,
	DbsApplicationParticipant,
	DbsAward,
	DbsCompany,
	DbsEmail,
	DbsEntityNote,
	DbsEvent,
	DbsPerson,
	DbsPersonTag,
	DbsPicJudge,
	DbsPicReader,
	DbsPicTeam,
	DbsPicTeamMember,
	DbsPitchContest,
	DbsPosition,
	DbsProgram,
	DbsRegion,
	DbsRelationship,
	DbsSite,
	DbsSiteProgram,
	DbsTag,
	DbsTagPrefix,
	DbsVenue,
	DbsWebLink
} from './index';


/**
 * A union of all the Dba, Dbd, Dbf, and Dbs concept types.
 * Each should have a unique _concept property value so you can
 * disciminate the union to get a typesafe set of properties
 * specific to one of the intefaces.
 */
export type Concept =
	//
	// Dba
	//
	DbaAccInterview |
	DbaAccInterviewerEvent |
	DbaAccJudgeEvent |
	DbaAccJudgement |
	DbaAccMatchAssessment |
	DbaAccMatchableMentor |
	DbaAccReading |
	DbaApplicationStatusLog |

	//
	// Dbd
	//
	DbdAccMeetingTimes |
	DbdAccLanguageSeason |
	DbdAccSeason |
	DbdAccStage |
	DbdApplicationStatus |
	DbdAwardKind |
	DbdAwardName |
	DbdAwardNameDecidingRole |
	DbdAwardNameEventType |
	DbdCity |
	DbdCompanyType |
	DbdDecidingRole |
	DbdEventType |
	DbdIndustry |
	DbdLanguage |
	DbdNoteCategory |
	DbdPhoneType |
	DbdPicStage |
	DbdPrefix |
	DbdProgramType |
	DbdQuestionsType |
	DbdQuestionType |
	DbdState |
	DbdSuffix |
	DbdTopic |
	DbdTopicMaterial |
	DbdTopicMaterialType |
	DbdWebLinkType |

	//
	// Dbf
	//


	//
	// Dbs
	//
	DbsAccelerator |
	DbsAccInterviewer |
	DbsAccJudge |
	DbsAccReader |
	DbsAccSessionSurveyResponse |
	DbsAccSessionTopicSurveyResponse |
	DbsAccSessionSpecialistSurveyResponse |
	DbsAccTeam |
	DbsAccTeamMember |
	DbsAgreementType |
	DbsAgreementSignature |
	DbsApplication |
	DbsApplicationParticipant |
	DbsAward |
	DbsCompany |
	DbsEmail |
	DbsEntityNote |
	DbsEvent |
	DbsPerson |
	DbsPersonTag |
	DbsPicJudge |
	DbsPicReader |
	DbsPicTeam |
	DbsPicTeamMember |
	DbsPitchContest |
	DbsPosition |
	DbsProgram |
	DbsRegion |
	DbsRelationship |
	DbsSite |
	DbsSiteProgram |
	DbsTag |
	DbsTagPrefix |
	DbsVenue |
	DbsWebLink;
