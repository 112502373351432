import { DbConceptName, Dbs, DbsPerson, DbsTagPrefix, Immutable, Site, TagPrefixId } from '@me-interfaces';


interface MutableDbsTag extends Dbs {

	readonly _concept: DbConceptName.Tag,

	readonly tagId: number,
	readonly tagPrefixId: TagPrefixId,
	readonly name: string,
	readonly isAutoTag: boolean,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


interface MutableTag extends MutableDbsTag {
	tagPrefix: DbsTagPrefix,
	updatedByPersonName: string,
	site: Site,
	fullName: string,
}


export interface DbsTag extends Immutable<MutableDbsTag> { }
export interface Tag extends Immutable<MutableTag> { }