export interface AccSessionSurveyAccess {
	/**
	 * The timeperiod a survey is submittable i.e. event end plus 5 days
	 */
	surveyOpen: boolean,
	showSurveyFields: boolean,
	attendee: boolean,
	markedPresent: boolean,
	accId: number,
	eventId: number,
	accName: string,
	pmEmail: string,
	eventStartUTC: number,
	eventEndUTC: number,
	toolStartUTC: number,
	toolEndUTC: number,
	accSessionSurveyResponseId: number,
}

