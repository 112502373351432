import { DbConceptName, Dbs } from "@me-interfaces";

export interface DbsEntityNote extends Dbs {

	readonly _concept: DbConceptName.EntityNote,

	readonly noteId: number,
	readonly entityId: number,
	readonly createdByPersonId: number,
	readonly createdUTC: number,
	readonly updatedByPersonId: number,
	readonly updatedUTC: number,
	readonly noteCategoryId: number,
	readonly siteId: number,
	readonly subject: string,
	readonly note: string,
}