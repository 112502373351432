/**
 * @deprecated Use AccMeetingTimes from the DataService instead
 */
export interface DdAccMeetingTimesRow {
	accMeetingTimesId: number,
	description: string,
	mon: number,
	tue: number,
	wed: number,
	thur: number,
	fri: number,
	sat: number,
	sun: number,
}