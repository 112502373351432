import { DbConceptName, Dbs, Immutable, RelationshipType } from '@me-interfaces';


interface MutableDbsRelationship extends Dbs {

	readonly _concept: DbConceptName.PersonPerson,

	readonly personId1: number,
	readonly personId2: number,
	readonly type?: RelationshipType,
	readonly notes?: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsRelationship extends Immutable<MutableDbsRelationship> { }