import { AppLink } from '@me-interfaces';

/**
 * An action that a user can select. An array of actions is passed into <dialog-frame actions='...'> and
 * they are rendered as buttons in the bottom of the panel. If the linkType is set to 'actionClick-event'
 * then the actionClicked event should be used: <dialog-frame (actionClicked)='...'>
 */
export interface DialogAction<T = void> extends AppLink<T> {

	/** A unique identifier for this action. e.g. 'save' */
	id: string,

	/** If true, the dialog will be closed when the button is clicked, after the action is applied. */
	willCloseDialog: boolean,

	cssClass?: string,
	enabled: boolean,
	visible: boolean,

}