import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from '@me-interfaces';

export type SiteSubArea =
	'accelerators' |
	'pitchContests' |
	'alumniNagging' |
	'config' |
	'roles' |
	'data';


export interface SiteAreaAccess_old extends AppAreaAccess {
	root: AppAreaAccessValue,
	sub: {
		accelerators: AppSubAreaAccessValue,
		pitchContests: AppSubAreaAccessValue,
		alumniNagging: AppSubAreaAccessValue,
		config: AppSubAreaAccessValue,
		roles: AppSubAreaAccessValue,
		data: AppSubAreaAccessValue,
		volunteer: AppSubAreaAccessValue,
	}
}