/**
 * The columns for each named DB Object are defined in db-object-columns.ts.
 * Each object name should have a matching interface which has readonly
 * properties that exactly align with its columns.
 */
export const enum DbConceptName {
	Accelerator = 'accelerator',
	AccInterview = 'accInterview',
	AccInterviewer = 'accInterviewer',
	AccInterviewerEvent = 'accInterviewer_event',
	AccJudge = 'accJudge',
	AccJudgement = 'accJudgement',
	AccJudgeEvent = 'accJudge_event',
	AccMatchAssessment = 'accMatchAssessment',
	AccMeetingTimes = 'accMeetingTimes',
	AccMatchableMentor = 'accMentor',
	AccReader = 'accReader',
	AccReading = 'accReading',
	AccLanguageSeason = 'accLanguageSeason',
	AccSeason = 'accSeason',
	AccStage = 'accStage',
	AccTeam = 'accTeam',
	AccTeamGoal = 'accTeamGoal',
	AccTeamGoalComment = 'accTeamGoalComment',
	AccTeamMember = 'accTeamMember',
	AccTeamReport = 'accTeamReport',
	AccTopic = 'accTopic',
	AccTopicSpecialist = 'accTopicSpecialist',
	AccSession = 'accSession',
	AccSessionAttendee = 'accSessionAttendee',
	AccSessionSurveyResponse = 'accSessionSurveyResponse',
	AccSessionTopicSurveyResponse = 'accSessionTopicSurveyResponse',
	AccSessionSpecialistSurveyResponse = 'accSessionSpecialistSurveyResponse',
	AccSessionTopic = 'accSessionTopic',
	Address = 'address',
	AgreementType = 'agreementType',
	AgreementVersion = 'agreementVersion',
	AgreementSignature = 'agreementSignature',
	Answer = 'answer',
	Answers = 'answers',
	Application = 'application',
	ApplicationParticipant = 'applicationParticipant',
	ApplicationParticipantPending = 'applicationParticipantPending',
	ApplicationDemographics = 'applicationDemographics',
	ApplicationStatus = 'applicationStatus',
	ApplicationStatusLog = 'applicationStatusLog',
	Award = 'award',
	AwardKind = 'awardKind',
	AwardName = 'awardName',
	AwardNameDecidingRole = 'awardName_decidingRole',
	AwardNameEventType = 'awardName_eventType',
	City = 'city',
	Company = 'company',
	CompanyAudit = 'companyAudit',
	/** @deprecated */ CompanyForMenu = 'companyForMenu',
	/** @deprecated */ CompanyProfile = 'companyProfile',
	CompanyType = 'companyType',
	CouncilMember = 'councilMember',
	Course = 'course',
	CourseSection = 'courseSection',
	CourseSegment = 'courseSegment',
	DecidingRole = 'decidingRole',
	Email = 'email',
	EntityAddress = 'entity_address',
	EntityNote = 'entityNote',
	Event = 'event',
	EventAttendee = 'eventAttendee',
	EventType = 'eventType',
	Industry = 'industry',
	Language = 'language',
	Mentor = 'mentor',
	NoteCategory = 'noteCategory',
	PendingEmail = 'pendingEmail',
	Person = 'person',
	PersonAudit = 'personAudit',
	/** @deprecated */ PersonForMenu = 'personForMenu',
	PersonPerson = 'person_person',
	PersonTag = 'person_tag',
	PhoneType = 'phoneType',
	PicJudge = 'picJudge',
	PicJudgement = 'picJudgement',
	PicReader = 'picReader',
	PicReading = 'picReading',
	PicStage = 'picStage',
	PicTeam = 'picTeam',
	PicTeamMember = 'picTeamMember',
	PitchContest = 'pitchContest',
	Position = 'position',
	Prefix = 'prefix',
	Program = 'program',
	ProgramIntern = 'programIntern',
	ProgramType = 'programType',
	PublicCompany = 'publicCompany',
	PublicPerson = 'publicPerson',
	Question = 'question',
	Questions = 'questions',
	QuestionsQuestion = 'questions_question',
	QuestionsType = 'questionsType',
	QuestionType = 'questionType',
	Region = 'region',
	Relationship = 'person_person',
	RelationshipType = 'relationshipType',
	AppSession = 'session',
	ScheduleTemplate = 'scheduleTemplate',
	ScheduleTemplateSession = 'scheduleTemplateSession',
	ScheduleTemplateSessionTopic = 'scheduleTemplateSession_topic',
	Site = 'site',
	SiteIntern = 'siteIntern',
	SiteOffice = 'siteOffice',
	SiteProgram = 'site_program',
	SiteVolunteerApplication = 'site_volunteerApplication',
	Specialist = 'specialist',
	State = 'state',
	Suffix = 'suffix',
	Tag = 'tag',
	TagPrefix = 'tagPrefix',
	Text = 'text',
	TimeZone = 'timeZone',
	Topic = 'topic',
	TopicMaterial = 'topicMaterial',
	TopicMaterialType = 'topicMaterialType',
	User = 'user',
	UserAgent = 'userAgent',
	Venue = 'venue',
	VolunteerApplication = 'volunteerApplication',
	WebLink = 'webLink',
	webLinkType = 'webLinkType',
	WebLinkType = 'webLinkType',
	Zip = 'zip',
}

export type ExplorableConceptName =
	DbConceptName.Accelerator |
	DbConceptName.AccTeam |
	DbConceptName.Application |
	DbConceptName.Company |
	DbConceptName.Event |
	DbConceptName.Person |
	DbConceptName.PicTeam |
	DbConceptName.PitchContest |
	DbConceptName.Region |
	DbConceptName.Site |
	DbConceptName.Venue;