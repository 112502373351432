/**
 * A field tool is a set of fields that are viewed/edited together.
 * Think of it as a form.
 * 
 * Access is defined by the person security flags, roles, and associations.
 * A tool cannot give a user extra access.  It could reduce it, though.
 * For instance, a staff member may have write access to an application
 * but when they view it from the Application tool it might still show
 * as read-only if the application was submitted or the program is not
 * currently accepting applications.
 */
export const enum FieldTool {
	Application = 'Application',
	Application_Reading = 'Application_Reading',
	Application_Interviewing = 'Application_Interviewing',
	Application_StaffEditor = 'Application_StaffEditor',
	Application_StaffViewer = 'Application_StaffViewer',
	Quarterlies = 'Quarterlies',
	Quarterlies_StaffEditor = 'Quarterlies_StaffEditor',
	Quarterlies_StaffViewer = 'Quarterlies_StaffViewer',
}