/**
 * @deprecated Use PitchContest from the DataService instead
 */
export interface DdPicRow {
	picId: number,
	picStageId: number,
	siteProgramId: number,
	directorId: number,
	managerId: number,
	name: string,
	longName: string,
	createdUTC: number,
	applStartUTC: number,
	applDuration: number,
}