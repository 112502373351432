import { DbConceptName, Dbs, ExplorablePackage, Immutable } from "@me-interfaces";


interface MutableDbsSite extends Dbs {

	readonly _concept: DbConceptName.Site,

	readonly siteId: number,
	readonly directorId: number,
	readonly altDirectorId: number | undefined,
	readonly name: string,
	readonly code: string,
	readonly stateCode: string,
	readonly baseName: string,
	readonly code6: string,
	readonly sortableName: string,
	readonly regionId: number,
	readonly createdUTC: number,
	readonly zipId: number,
	readonly timeZoneId: number,
	readonly location: string,
	readonly mcGroupNameEN: string | undefined,
	readonly mcGroupNameES: string | undefined,
	readonly isActive: boolean,
	readonly eforallOrgEn: string | undefined,
	readonly eforallOrgEs: string | undefined,
	readonly emailEn: string,
	readonly emailEs: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
	readonly hidden: boolean,
}


interface MutableSite extends MutableDbsSite {
	directorName: string,
	altDirectorName: string | undefined,
	updatedByPersonName: string,
	stateName: string,
}


export interface DbsSite extends Immutable<MutableDbsSite> { }
export interface Site extends ExplorablePackage<DbConceptName.Site>, Immutable<MutableSite> { }