import { DbConceptName, Dbd, Immutable, WebLinkTypeId } from '@me-interfaces';


interface MutableDbdWebLinkType extends Dbd {

	readonly _concept: DbConceptName.WebLinkType,

	webLinkTypeId: WebLinkTypeId,
	name: string,
	displayOrder: number,
}


export interface DbdWebLinkType extends Immutable<MutableDbdWebLinkType> { }