import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdState extends Dbd {

	readonly _concept: DbConceptName.State,

	stateCode: string,
	state: string,
}


export interface DbdState extends Immutable<MutableDbdState> { }