
import {
	Assessment,
	PicApplicant,
	PicPresentType,
	MinimalVolunteerApplicant,
	Answer,
	AdminAccReader,
	AdminAccJudge,
	PicJudge_old,
	Old_PicReader,
	Old_AccInterviewer,
	AdminAccelerator,
	AdminPitchContest,
	Old_MinimalContact,
	Old_MinimalPersonWithNoteCountsAndTags,
	DbsEvent
} from '@me-interfaces';
import { AccTeamJudgement } from './accelerator';



//
//
// old site.state.ts
//
//

// Read Tool

export interface PitchContestApplicantReadingList {
	applicationId: number,
	company: string,
	assessment: number | undefined,
	answers: Answer[],
}

export interface PitchContestApplicantReadings {
	applicantsRead: PitchContestApplicantReadingList[],
	nextPicApplicationId: number | undefined,
	picReaderId: number | undefined,
}

export interface PitchContestApplicantForAssessment {
	applicant: PicApplicant,
	assessment: Assessment,
}

// Judge Tool

export interface PitchContestJudgeTeam {
	picTeamId: number,
	name: string,
	judgement: {
		existingAnswers: boolean,
		answers: Answer[],
	}
}

// export interface PitchContestJudgeEvent extends Event {
// 	teams: PitchContestJudgeTeam[],
// }

export interface PitchContestJudgeTeams extends DbsEvent {
	teams: PitchContestJudgeTeam[],
}


/**
 * An array of these is used on the reading selection page
//  */
export interface SiteAcceleratorApplicantReading {
	accApplicantId: number,
	applicationStatusId: number,
	company: string,
	rating: number | null,	// rating given by the current logged in person (if any)
	readings: number,		// count of completed readings for this applicant
}

/**
 * Resolved for:
 *    /access/sites/:site/accelerator/:accId/judge
 */

export interface SiteAcceleratorJudgeEvent extends DbsEvent {
	teams: AccTeamJudgement[],
}

export interface SiteAcceleratorJudgeTeams {
	events: SiteAcceleratorJudgeEvent[],
}


/**
 * An array of these is used on the interview selection page
 */


export interface SiteAcceleratorJudgeQuestions {
	questionId: number,
	questionsId: number,
	internalLabel: string,
	isRequired: boolean,
	textEN: string,
	questionTypeId: number,
}

export interface SiteDashboard {
	tags: string,
	siteCode: string,
	accelerators: {
		accId: number,
		stageId: number,
		accName: string,
		applicant: {
			accApplicantId: number,
			applicationStatusId: number,
		} | undefined,
	}[],
}


//
//
// old site-admin.state.ts
//
//





/**
 * This is loaded for /sites/{site}/admin
 */

export interface AcceleratorMentor {
	contact: Old_MinimalPersonWithNoteCountsAndTags,
	hasCoachTag: boolean,
	demographicsGiven: string,
	accTeamId: number | undefined,
	team: string | undefined,
	teamRole: 'E' | 'C' | 'M' | 'X' | undefined,
	useCount?: number,
}

export interface SiteAdminAcceleratorCohortTeamMember {
	accTeamId: number,
	personId: number,
	role: 'E' | 'C' | 'M' | 'X',
	notes: string,
	firstName: string,
	middleInit: string,
	lastName: string,
	hasAvatar: boolean,
	title: string | undefined,
	isAdmin: boolean,
	isFounder: boolean,
	demographicsGiven: string,
}

export interface AcceleratorCohort {
	name: string,
	Es: number,
	Xs: number,
	Cs: number,
	Ms: number,
	teamDriveId?: string,
	accTeamId?: number,
	entrepreneursDemographics: string,
	droppedOutWeek?: number,
	allEmails: string,
	entrepreneurEmails: string,
	coachEmails: string,
	mentorEmails: string,
	schedule: [number, number, number, number, number, number],
}

export interface SiteAdminAcceleratorEventsFinalPitchEvent extends DbsEvent {
	venueName: string,
	judgeCount: number,
	teamCount: number,
}

export interface SiteAdminAcceleratorEventsFinalPitchTeam {
	accId: number,
	accTeamId: number,
	name: string,
	finalEventId: number,
}

export interface SiteAdminAcceleratorEventsFinalPitch {
	events: DbsEvent[],
	judges: AdminAccJudge[],
	teams: SiteAdminAcceleratorEventsFinalPitchTeam[],
}

export interface SiteAdminAcceleratorEventsReading {
	events: DbsEvent[],
	readers: AdminAccReader[],
	applications: SiteAdminAcceleratorEventsReadingApplication[],
}

export interface SiteAdminAcceleratorEventsReadingApplication {
	accId: number,
	accApplicantId: number,
	ideaName: string,
	readings: number,
}

export interface AccInterviewingEvent extends DbsEvent {
	venueName: string,
	interviewerCount: number,
	applicationCount: number,
}

//Pitch Contest stuff

export interface PitchParticipants {
	name: string,
	Es: number,
	picTeamId: number,
	entrepreneursDemographics: string,
	allEmails: string,
	presentType: 'Tabler' | 'Pitcher' | 'Audience' | 'Wildcard',
	judgements: number,
}

export interface SiteAdminPitchTeamMember {
	picTeamId: number,
	personId: number,
	notes: string,
	firstName: string,
	lastName: string,
	hasAvatar: boolean,
	title: string | undefined,
	isAdmin: boolean,
	isFounder: boolean,
	demographicsGiven: string,
}

export interface SiteAdminPitchTeam {
	picId: number,
	picTeamId: number,
	notes: string,
	hasJudgementsOrWinner: boolean,
	presentType: PicPresentType,
	applicantPersonId: number,
	companyId: number,
	applicationId: number,
	origIsNonProfit: boolean,
	nowIsNonProfit: boolean | undefined,
	origCompanyName: string,
	nowCompanyName: string | undefined,
	companyType: string | undefined,
	members: SiteAdminPitchTeamMember[],
	companyPositions: {
		personId: number,
		firstName: string,
		lastName: string,
		title: string,
	}[],
}


//
//
// old contacts-person.state.ts
//
//

export interface PersonInfo {
	firstName: string,
	lastName: string,
	email: string,
	phone: string | undefined,
	canText: boolean,
	inUS: boolean,
	address: string | undefined,
	zipId: number | undefined,
	city: string | undefined,
	website: string | undefined,
	linkedin: string | undefined,
	facebook: string | undefined,
	twitter: string | undefined,
	notes: string | undefined,
	pendingMailChimp: boolean,
	salesforce: string | undefined,
	isCrmUser: boolean,
	isEForAllAdmin: boolean,
	isTechAdmin: boolean,
	isDeceased: boolean,
	hasOptOutDemographics: boolean,
	defaultPosition: PersonPosition | undefined,
}

export interface PersonLanguage {
	description: string,
	bio: string,
}

export interface PersonPosition {
	positionId: number,
	companyId: number,
	companyName: string,
	//companyLogo: string,
	title: string,
	isAdmin: boolean,
	isFounder: boolean,
	isDefaultPosition: boolean,
}

export interface PersonMentor {
	bio: string,
	//teams: [],
}


export interface menu {
	title: string,
	routerLink: string,
	icon: string,
	selected: boolean,
	expanded: boolean,
	order: number,
	subMenu: menu[],
}