export interface AccMatchingAssessment {
	events?: {
		eventId: number,
		startUTC: number,
		venueName: string,
	}[],
	mentors?: {
		personId: number,
		firstName: string,
		lastName: string,
		hasAvatar: boolean,
		pitch: string,
		matchingEventId?: number,
		assessment?: number,
	}[],
	entrepreneurs?: {
		accTeamId: number,
		ideaName: string,
		firstName: string,
		lastName: string,
		personId: number,
		hasAvatar: boolean,
		idea: string,
		assessment?: number,
	}[],
}