import {
	AccAssociationExtract,
	DbcPersonForMenu,
	DbsPosition
} from "@me-interfaces";

export interface CompanyPositionExtract {
	position: DbsPosition,
	person: DbcPersonForMenu,
}

export interface CompanyExtracts {
	readonly accs: AccAssociationExtract[],
	readonly positions: CompanyPositionExtract[],
}