import { DbcCompanyProfile, DbsPerson } from "@me-interfaces";

/**
 * Each entity represents either a person or a company.
 * One of the profile properties should be set for the entity
 * and the other will be undefined.
 */
export interface EntityProfile {
	person?: DbsPerson,
	company?: DbcCompanyProfile,
}