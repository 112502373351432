import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAccMeetingTimes extends Dbd {

	readonly _concept: DbConceptName.AccMeetingTimes,

	accMeetingTimesId: number,
	description: string,
	mon: number,
	tue: number,
	wed: number,
	thur: number,
	fri: number,
	sat: number,
	sun: number,
}


export interface DbdAccMeetingTimes extends Immutable<MutableDbdAccMeetingTimes> { }