import { DbConceptName, DbdLanguage, DbdTopic, Dbs, Immutable, Person, Site } from '@me-interfaces';

export type SpecialistStatus = 'Consider' | 'Active' | 'Inactive';

interface MutableDbsSpecialist extends Dbs {

	readonly _concept: DbConceptName.Specialist,

	specialistId: number,
	personId: number,
	siteId: number,
	topicId: number,
	languageId: number,
	status: SpecialistStatus,
	note: string,
	updatedUTC: number,
	updatedByPersonId: number,

	//
	// Additional columns not in DB
	//
	associationCounts: number,
}


interface MutableSpecialist extends MutableDbsSpecialist {
	person: Person,
	site: Site,
	topic: DbdTopic,
	language: DbdLanguage,
	updatedByPersonName: string,
}


export interface DbsSpecialist extends Immutable<MutableDbsSpecialist> { }
export interface Specialist extends Immutable<MutableSpecialist> { }