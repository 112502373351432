import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsAgreementVersion extends Dbs {

	readonly _concept: DbConceptName.AgreementVersion,

	readonly agreementVersionId: number,
	readonly agreementTypeId: number,
	readonly version: number,
	readonly introductionES: string,
	readonly agreementES: string,
	readonly introductionEN: string,
	readonly agreementEN: string,
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsAgreementVersion extends Immutable<MutableDbsAgreementVersion> { }