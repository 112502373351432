import { Dbc } from "../dbc";


/**
 * @deprecated
 */
export interface DbcPersonForMenu extends Dbc {
	personId: number,
	photoUTC?: number,
	_name: string,
}