import { ApplicationStatusId, DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdApplicationStatus extends Dbd {

	readonly _concept: DbConceptName.ApplicationStatus,

	applicationStatusId: ApplicationStatusId,
	name: string,
}


export interface DbdApplicationStatus extends Immutable<MutableDbdApplicationStatus> { }