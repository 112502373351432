export interface UserUpdateDetails {
	firstName: string,
	middleInit: string,
	lastName: string,
	phone: string,
	inUS: boolean,
	address: string,
	zipId?: number,
	website: string,
	linkedin: string,
	facebook: string,
	twitter: string,
}