import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaScheduleTemplateSession extends Dba {

	readonly _concept: DbConceptName.ScheduleTemplateSession,

	scheduleTemplateSessionId: number,
	scheduleTemplateId: number,
	startDay: string,
	startTime: string,
	endDay: string,
	endTime: string,
}


export interface DbaScheduleTemplateSession extends Immutable<MutableDbaScheduleTemplateSession> { }