import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAwardNameDecidingRole extends Dbd {

	readonly _concept: DbConceptName.AwardNameDecidingRole,

	awardNameDecidingRoleId: number,
	awardNameId: number,
	decidingRoleId: number,
}


export interface DbdAwardNameDecidingRole extends Immutable<MutableDbdAwardNameDecidingRole> { }