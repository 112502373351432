import { DbsEvent } from './dbs';
import {
	DbdAccLanguageSeason, DbdAccMeetingTimes, DbdAccSeason, DbdAccStage,
	DbdApplicationStatus,
	DbdAwardKind,
	DbdAwardName, DbdCity,
	DbdCompanyType,
	DbdDecidingRole,
	DbdEventType,
	DbdIndustry,
	DbdLanguage,
	DbdNoteCategory,
	DbdPhoneType,
	DbdPicStage,
	DbdPrefix,
	DbdProgramType,
	DbdQuestionsType,
	DbdQuestionType,
	DbdState,
	DbdSuffix,
	DbdTopic,
	DbdTopicMaterial,
	DbdTopicMaterialType,
	DbdWebLinkType,
	DbsAccelerator, DbsCompany,
	DbsEmail,
	DbsEntityNote,
	DbsPerson, DbsPitchContest,
	DbsPosition,
	DbsProgram, DbsSite, DbsTagPrefix,
	DbsVenue, Tag
} from './index';


/**
 * This union type is similar to the Concept union type except it only
 * includes items with some semblance of a name directly attached to it
 * that can be retrieved with a call to dataService.getConceptName();
 *
 * Some concepts require the package equivalent.
 */
export type NamedConcept =

	//
	// Dbd
	//
	DbdAccMeetingTimes |
	DbdAccLanguageSeason |
	DbdAccSeason |
	DbdAccStage |
	DbdApplicationStatus |
	DbdAwardKind |
	DbdAwardName |
	DbdCity |
	DbdCompanyType |
	DbdDecidingRole |
	DbdEventType |
	DbdIndustry |
	DbdLanguage |
	DbdNoteCategory |
	DbdPhoneType |
	DbdPicStage |
	DbdPrefix |
	DbdProgramType |
	DbdQuestionsType |
	DbdQuestionType |
	DbdState |
	DbdSuffix |
	DbdTopic |
	DbdTopicMaterial |
	DbdTopicMaterialType |
	DbdWebLinkType |

	//
	// Dbs
	//
	DbsAccelerator |
	DbsCompany |
	DbsEmail |
	DbsEntityNote |
	DbsEvent |
	DbsPerson |
	DbsPitchContest |
	DbsPosition |
	DbsProgram |
	DbsSite |
	DbsTagPrefix |
	DbsVenue |

	//
	// Packages
	//
	Tag;
