import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccJudgeEvent extends Dba {

	readonly _concept: DbConceptName.AccJudgeEvent,

	accJudgeId: number,
	eventId: number,
}


export interface DbaAccJudgeEvent extends Immutable<MutableDbaAccJudgeEvent> { }