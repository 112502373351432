import { Accelerator, DbConceptName, Dbs, Immutable, Person } from '@me-interfaces';


interface MutableDbsAccReader extends Dbs {

	readonly _concept: DbConceptName.AccReader,

	accReaderId: number,
	personId: number,
	accId: number,
	readApplicationIds: number[],
	updatedUTC: number,
	updatedByPersonId: number,
}


interface MutableAccReader extends MutableDbsAccReader {
	person: Person,
	accelerator: Accelerator,
	updatedByPersonName: string,
}


export interface DbsAccReader extends Immutable<MutableDbsAccReader> { }
export interface AccReader extends Immutable<MutableAccReader> { }