import { Accelerator, DbConceptName, Dbs, Immutable, Person } from '@me-interfaces';


interface MutableDbsAccInterviewer extends Dbs {

	readonly _concept: DbConceptName.AccInterviewer,

	accInterviewerId: number,
	personId: number,
	accId: number,
	interviewedApplicationIds: number[],
	updatedUTC: number,
	updatedByPersonId: number,
}


interface MutableAccInterviewer extends MutableDbsAccInterviewer {
	person: Person,
	accelerator: Accelerator,
	updatedByPersonName: string,
}


export interface DbsAccInterviewer extends Immutable<MutableDbsAccInterviewer> { }
export interface AccInterviewer extends Immutable<MutableAccInterviewer> { }