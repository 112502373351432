export interface AccApplicantMinimal {
	person: {
		personId: number,
		name: string,
	},
	companies: {
		companyId: number,
		name: string,
	}[],
	existingApplicationId?: number,
}
