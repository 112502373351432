import { Dbc } from "@me-interfaces";


export interface DbcAccApplication extends Dbc {
	applicationId: number,
	accId: number,
	personId: number,
	applicationStatusId: number,
	isUnemployed: boolean,
	companyId: number,
	isNonProfit: boolean,
	ideaName: string,
	answersId: number,
	interviewEventId: number,
	notes: string,
}