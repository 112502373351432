import { WebLinkTypeId } from "@me-interfaces";

export type Link = AddressLink | CallLink | EmailLink | SmsLink | WebLink | ToolLink;


interface LinkBase {
	linkType: 'address' | 'email' | 'call' | 'sms' | 'webLink' | 'tool';
}

export interface AddressLink extends LinkBase {
	linkType: 'address',
	street?: string,
	zipId: number,
}

export interface CallLink extends LinkBase {
	linkType: 'call',
	phone: string,
	ext?: string,
}

export interface EmailLink extends LinkBase {
	linkType: 'email',
	email: string,
}

export interface SmsLink extends LinkBase {
	linkType: 'sms',
	phone: string,
}

export interface WebLink extends LinkBase {
	linkType: 'webLink',
	url: string,
	webLinkTypeId: WebLinkTypeId,
	overrideText?: string,
}

export interface ToolLink extends LinkBase {
	linkType: 'tool',
	url: string,
	enabled: boolean,
	overrideText?: string,
}