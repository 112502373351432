export type TagRole
	= 'Reader'
	| 'Interviewer'
	| 'Cohort'
	| 'Mentor'
	| 'Coach'
	| 'Judge'
	| 'Specialist'
	| 'EventHelper'
	| 'Leadership'
	| 'Coworker';