import { DbConceptName, Dbs, Immutable } from "@me-interfaces";


interface MutableDbsEmail extends Dbs {

	readonly _concept: DbConceptName.Email,

	readonly emailId: number,
	readonly email: string,
	readonly personId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsEmail extends Immutable<MutableDbsEmail> { }