import { Dbc, DbsWebLink, ImmutableObject } from "@me-interfaces";
import { Address } from "../../db-concepts";


export interface DbcPublicCompany extends Dbc {
	readonly companyId: number,
	readonly entityId: number,
	readonly companyTypeId: number,
	readonly longName: string,
	readonly shortName: string,
	readonly _name: string,
	readonly inUS: boolean,
	readonly address: string,
	readonly zipId: number,
	//TODO: Should phone really be here?
	readonly phone: string,
	readonly phoneTypeId: number,
	readonly offerings: string,
	readonly industryId: number,

	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}

export interface PublicCompanyForDialog extends DbcPublicCompany {
	readonly phone: string,
	readonly phoneTypeId: number,
	readonly fullAddress?: Address,
	readonly webLinks: readonly ImmutableObject<DbsWebLink>[],
}