export interface TeamProgressReport {
	accTeamId: string,
	accelerator: string,
	company: string,
	status: string,
	reportImportance: string,
	lastYearUTC: number | undefined,
	thisYearUTC: number | undefined,
	entrepreneurs: {
		personId: number,
		_name: string,
		phone: string | undefined,
		_email: string,
	}[],
	admins: {
		personId: number,
		_name: string,
		phone: string | undefined,
		_email: string,
	}[],
}