import { AccTeam, Dba, DbConceptName, DbsAccSessionSurveyResponse, Immutable, Person } from '@me-interfaces';

export type SessionAttendeeStatus = 'Present' | 'Absent';

interface MutableDbaAccSessionAttendee extends Dba {

	readonly _concept: DbConceptName.AccSessionAttendee,

	accSessionAttendeeId: number,
	accSessionId: number,
	personId: number,
	accTeamId: number,
	status: SessionAttendeeStatus,
}


export interface DbaAccSessionAttendee extends Immutable<MutableDbaAccSessionAttendee> { }


export interface DbaSessionAttendee {
	accSessionAttendeeId: number,
	attendee: DbaAccSessionAttendee,
	person: Person,
	accTeam: AccTeam,
	accSessionSurveyResponse: DbsAccSessionSurveyResponse,
}