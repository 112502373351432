export interface NoteFilterList {
	authors: {
		name: string,
		personId: number,
		hasAvatar: boolean,
	}[],
	editedBy: {
		name: string,
		personId: number,
		hasAvatar: boolean,
	}[],
}