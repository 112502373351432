export type FieldTarget =
	'answer-text' |
	'application-annualRevenue' |
	'application-companyAddress' |
	'application-companyIndustryId' |
	'application-companyName' |
	'application-companyOfferings' |
	'application-companyPhone' |
	'application-companyTypeId' |
	'application-eventId' |
	'application-notes' |
	'application-numContractors' |
	'application-numFullTimeEmployees' |
	'application-numPartTimeEmployees' |
	'application-restOfTeam' |
	'application-hasFinancials' |
	'application-hasCashFlowProjections' |
	'application-commercialSpace' |
	'applicationParticipant-doingItFullTime' |
	'applicationParticipant-lastYearCompensation' |
	'applicationParticipant-employmentAddress' |
	'applicationParticipant-employmentStatusId' |
	'applicationParticipant-employmentZipId' |
	'applicationParticipant-headOfHousehold' |
	'applicationParticipant-homeAddress' |
	'applicationParticipant-homeZipId' |
	'applicationParticipant-title' |
	'applicationParticipant-householdIncome' |
	'person-birthday' |
	'person-firstName' |
	'person-gender' |
	'person-genderOther' |
	'person-handicapped' |
	'person-hasOptOutDemographics' |
	'person-hispanic' |
	'person-immigrant' |
	'person-isDeceased' |
	'person-lastName' |
	'person-middleInit' |
	'person-nativeEnglish' |
	'person-nativeSpanish' |
	'person-phone' |
	'person-races' |
	'person-raceOther' |
	'person-veteran'
	;