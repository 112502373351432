import { DbsPerson } from '@me-interfaces';

export interface ReviewerFeedbackData {
	reviewerId: number,
	reviewer: DbsPerson,
	originalFeedback: string,
	feedbackForEforAll: string,
	polishedFeedback: string,
	polishedFeedbackSkipped: boolean,
}

export interface FeedbackData {
	applicationId?: number,
	teamId?: number,
	firstNames: string,
	emails: string,
	company: string,
	hasLogo: boolean,
	status: string,
	feedback: ReviewerFeedbackData[],
}