import { FieldsAndValues } from "@me-interfaces";

export interface QuarterlyFields {
	overview: {
		accId: number,
		enabled: {
			q0: boolean,
			q1: boolean,
			q2: boolean,
			q3: boolean,
			q4: boolean,
		},
	},
	q0: FieldsAndValues,
	q1: FieldsAndValues,
	q2: FieldsAndValues,
	q3: FieldsAndValues,
	q4: FieldsAndValues,
}