import { Old_MinimalContact } from '@me-interfaces';

export interface ReviewerFeedback {
	reviewerId: number,
	reviewer: Old_MinimalContact,
	originalFeedback: string,
	feedbackForEforAll: string,
	polishedFeedback: string,
	polishedFeedbackSkipped: boolean,
}

export interface Feedback {
	applicantId?: number,
	teamId?: number,
	firstNames: string,
	emails: string,
	company: string,
	hasLogo: boolean,
	readings: {
		status: string,
		feedback: ReviewerFeedback[],
	},
	interviews?: {
		status: string,
		feedback: ReviewerFeedback[],
	},
	judgements: {
		status: string,
		feedback: ReviewerFeedback[],
	},
}

