import { Dbc, DbConceptDataType } from "@me-interfaces";


/**
 * Base interface for all Singleton interfaces
 */
export interface Dbs extends Dbc {
	_dt: DbConceptDataType.AdminSingletonData;
	updatedUTC: number,
	updatedByPersonId: number,
}