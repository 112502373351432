export interface LayoutDimensions {

	/** Width of the left menu, which might be full width, collapsed, or completely hidden. */
	menuWidth: number,

	/** Code that describes the breakpoint range. */
	breakpoint: 'xs' | 'sm' | 'md' | 'lg',

	/** Width of the app content area minus margins and menu */
	contentWidth: number,

	/** Height of the app content area minus margins, navbar, and breadcrumbs */
	contentHeight: number,

	/** Same as contextWidth but removes horizontal margins when breakpoint is xs */
	contentWidthSnugXS: number,

	/** Same as contextHeight but removes vertical margins when breakpoint is xs */
	contentHeightSnugXS: number,

	/** Width of the device screen */
	screenWidth: number,

	/** Height of the device screen */
	screenHeight: number,

	/** Width of the renderable area within the browser window */
	windowWidth: number,

	/** Height of the renderable area within the browser window */
	windowHeight: number,

	/**
	 * If page tabs are sized to contentWidth then use pageTabContentWidth for the width within the tab content area.
	 * @deprecated Use page-tabs part instead.
	 */
	pageTabContentWidth: number, //TODO: Remove all used of this and remove

	/** If page tabs are sized to contentHeight then use pageTabContentHeight for the height within the tab content area. */
	pageTabContentHeight: number,

}