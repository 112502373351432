export const enum AccStageId {
	Setup = 1,
	Accepting = 10,
	Reading = 20,
	Interviewing = 30,
	SelectingCohort = 40,
	MatchingMentors = 50,
	Curriculum = 60,
	SelectingWinners = 70,
	Quarterlies = 80,
	Complete = 90,
}