import { Dbc } from "@me-interfaces";


export interface DbcAccTeamGoal extends Dbc {
	readonly accTeamGoalId: number,
	readonly accTeamId: number,
	readonly quarter: number,
	readonly summary: string,
	readonly details: string,
	readonly progress: string,
	readonly createdUTC: number,
	readonly createdByPersonId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,

	createdByPersonName: string,
	updatedByPersonName: string,
}