import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaScheduleTemplateSessionTopic extends Dba {

	readonly _concept: DbConceptName.ScheduleTemplateSessionTopic,

	scheduleTemplateSessionId: number,
	topicId: number,
	scheduleTemplateId: number,
}


export interface DbaScheduleTemplateSessionTopic extends Immutable<MutableDbaScheduleTemplateSessionTopic> { }