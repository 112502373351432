import { SevenNums } from "@me-interfaces";

export interface AccCohortSingleTeam {
	name: string,
	Es: number,
	Xs: number,
	Cs: number,
	Ms: number,
	teamDriveId?: string,
	accTeamId?: number,
	entrepreneursDemographics: string,
	droppedOutWeek?: number,
	allEmails: string,
	entrepreneurEmails: string,
	coachEmails: string,
	mentorEmails: string,
	schedule: SevenNums,
}


export interface AccCohort {
	teams: AccCohortSingleTeam[],
}