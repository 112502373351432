import { DbConceptName, Dbd, Immutable, NoteCategoryId } from "@me-interfaces";


interface MutableDbdNoteCategory extends Dbd {

	readonly _concept: DbConceptName.NoteCategory,

	noteCategoryId: NoteCategoryId,
	label: string,
}


export interface DbdNoteCategory extends Immutable<MutableDbdNoteCategory> { }