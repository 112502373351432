import { DbConceptName, Dbd, Immutable, PrefixId } from '@me-interfaces';


interface MutableDbdPrefix extends Dbd {

	readonly _concept: DbConceptName.Prefix,

	prefixId: PrefixId,
	name: string,
}


export interface DbdPrefix extends Immutable<MutableDbdPrefix> { }