import { DbConceptName, Dbs, Immutable, WebLinkTypeId } from '@me-interfaces';


interface MutableDbsWebLink extends Dbs {

	readonly _concept: DbConceptName.WebLink,

	readonly webLinkId: number,
	readonly entityId: number,
	readonly webLinkTypeId: WebLinkTypeId,
	readonly url: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsWebLink extends Immutable<MutableDbsWebLink> { }