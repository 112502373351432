import { Old_MinimalContact, Old_MinimalCompany, Answer, DbsEvent } from '@me-interfaces'

export interface RatingsData {
	person: Old_MinimalContact,
	ratings: {
		company: Old_MinimalCompany,
		rating?: number,
		answers: Answer[],
		event?: DbsEvent,
	}[]
}