import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccInterviewerEvent extends Dba {

	readonly _concept: DbConceptName.AccInterviewerEvent,

	accInterviewerId: number,
	eventId: number,
}


export interface DbaAccInterviewerEvent extends Immutable<MutableDbaAccInterviewerEvent> { }