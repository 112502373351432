export interface AccSessionSurveyAnswers {
	accSessionId: number,
	sessionAnswers: { 'accessibility': number, 'communication': number, 'organization': number, 'other': string },
	topicAnswers: {
		topicId: number,
		keyAndValue: {
			'relevance': number,
			'need': number,
			'understanding': number,
			'duration': number,
			'other': string,
		},
		specialistAnswers: {
			personId: number,
			keyAndValue: {
				'professionalism': number,
				'knowledge': number,
				'effectiveness': number,
				'other': string
			}
		}[]
	}[]

}