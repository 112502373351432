import { AccMatchingMeetings, AccMatchingMeetingsAll, Answer, DbaAccInterview, DbaAccInterviewerEvent, DbaAccJudgeEvent, DbaAccJudgement, DbaAccMatchableMentor, DbaAccMatchAssessment, DbaAccReading, DbaAccSession, DbaAccSessionAttendee, DbaAccSessionTopic, DbaAccTopic, DbaAccTopicSpecialist, DbaApplicationDemographics, DbaApplicationStatusLog, DbcAccTeamGoal, DbcAccTeamGoalComment, QuarterlyGoalFlagsByQuarter } from "@me-interfaces";


export interface AccTeamGoalsCommentsAndAccess {
	accTeamId: number,
	goals: DbcAccTeamGoal[],
	comments: {
		comment: DbcAccTeamGoalComment,
		commenterName: string,
	}[],
	access: {
		canAddGoal: boolean,
		canEditGoal: boolean,
		canAddComment: boolean,
	},
}


export interface AccAreaRawData {

	accId: number,


	application: {
		statusLogs: DbaApplicationStatusLog[],
		ideaTexts: { [appId: number]: string },
		demographicsByApplicationId?: Record<number, DbaApplicationDemographics>,
	},


	reading: {
		/** accReaderId: maxReads */
		readerMaxReadsMap: { [index: number]: number },
		assessments: {
			reading: DbaAccReading,
			answers: Answer[]
		}[],
		defaultQuestions: Answer[],
	},


	interviewing: {
		interviewerEvents: DbaAccInterviewerEvent[],
		assessments: {
			interview: DbaAccInterview,
			answers: Answer[]
		}[],
		defaultQuestions: Answer[],
	},


	mentorMatching: {
		matchableMentors: DbaAccMatchableMentor[],
		assessments: DbaAccMatchAssessment[],
		v2Schedules: AccMatchingMeetingsAll,
	},


	team: {
		goals: AccTeamGoalsCommentsAndAccess[],
	},


	judging: {
		judgeEvents: DbaAccJudgeEvent[],
		assessments: {
			judgement: DbaAccJudgement,
			answers: Answer[]
		}[],
		defaultQuestions: Answer[],
	},


	quarterlies: {
		goalFlags: QuarterlyGoalFlagsByQuarter,
	},

	curriculum: {
		accTopics: DbaAccTopic[],
		accTopicSpecialists: DbaAccTopicSpecialist[],
		sessions: DbaAccSession[],
		sessionTopics: DbaAccSessionTopic[],
		sessionAttendees: {
			attendee: DbaAccSessionAttendee,
		}[],
	}
}