import { Old_MinimalContact, Old_MinimalSite, Old_SiteProgram, City } from '@me-interfaces';

/**
 * @deprecated Use Site from DataService instead
 */
export interface Old_Site extends Old_MinimalSite {
	director: Old_MinimalContact,
	altDirector?: Old_MinimalContact,
	sitePrograms: Old_SiteProgram[],
	city: City,
}