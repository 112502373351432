import { DbConceptName, Dbd, Immutable, ProgramTypeId } from '@me-interfaces';


interface MutableDbdProgramType extends Dbd {

	readonly _concept: DbConceptName.ProgramType,

	programTypeId: ProgramTypeId,
	name: string,
}


export interface DbdProgramType extends Immutable<MutableDbdProgramType> { }