import { UserAgreementType } from "@me-interfaces";

export interface AccMatching {
	role?: 'M' | 'E',
	accTeamId?: number,
	pitch?: string,
	events?: {
		eventId: number,
		startUTC: number,
		endUTC: number,
		venueId: number,
		venueName: string,
		attendees: number,
	}[],
	matchingEventId?: number,
	agreementSignature?: {
		agreementSignatureId: number,
		agreementVersionId: number,
		updatedUTC: number,
	},
	agreementType?: UserAgreementType,
	allAssessmentsGiven: boolean,
	isScheduleSet: boolean,
	version: 1 | 2,
}