import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccTopic extends Dba {

	readonly _concept: DbConceptName.AccTopic,

	accTopicId: number,
	accId: number,
	topicId: number,
}


export interface DbaAccTopic extends Immutable<MutableDbaAccTopic> { }