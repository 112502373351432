import { Accelerator, Application, Company, DbConceptName, Dbs, DbsAccTeamMember, DbsPerson, ExplorablePackage, Immutable, SevenNums } from "@me-interfaces";

interface MutableDbsAccTeam extends Dbs {

	readonly _concept: DbConceptName.AccTeam,

	readonly accTeamId: number,
	readonly accId: number,
	readonly applicationId: number,
	readonly withdrawnUTC: number,
	readonly teamDriveId: string,
	readonly finalEventId: number,
	readonly notes: string,
	readonly droppedOutWeek: number,
	readonly mon: number,
	readonly tue: number,
	readonly wed: number,
	readonly thur: number,
	readonly fri: number,
	readonly sat: number,
	readonly sun: number,
	readonly promoterScore: number,
	readonly reportImportance: 'Low' | 'Medium' | 'High' | 'Critical',
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


interface MutableAccTeam extends MutableDbsAccTeam {
	//
	// Upward facing references (singletons, db, or packages) no arrays
	//
	accelerator: Accelerator,
	application: Application,
	company: Company,

	//
	// Downward facing references (singletons or db only) arrays allowed
	//
	members: { member: DbsAccTeamMember, person: DbsPerson }[],

	updatedByPersonName: string,
	awards: { awardId: number, value: number }[],
	awardedValue: number,
	/**
	 * Mon to Sun schedule bits after filtering out blockout time
	 */
	schedule: SevenNums,
}


export interface DbsAccTeam extends Immutable<MutableDbsAccTeam> { }
export interface AccTeam extends ExplorablePackage<DbConceptName.AccTeam>, Immutable<MutableAccTeam> { }