import { Dba } from '@me-interfaces';

export interface DbaApplicationDemographics extends Dba {

	bipoc: number,

	asian: number,
	black: number,
	nativeAmerican: number,
	pacificIslander: number,
	otherRace: number,
	white: number,

	hispanic: number,
	immigrant: number,

	female: number,
	notMale: number,

	veteran: number,
	deceased: number,
	spanish: number,
	english: number,
}