import { DbConceptName, Dbs, Immutable } from "@me-interfaces";
import { EntityExtracts } from "../../db-extracts";


interface MutableDbsEntity extends Dbs {

	readonly _concept: DbConceptName.Person | DbConceptName.Company,

	readonly entityId: number,
	readonly entityType: string,
	readonly photoUTC: number,
	readonly zipId: number,
	readonly address: string,
	readonly inUS: boolean,
	readonly _entityExtracts: EntityExtracts,
	readonly _name: string,
}


export interface DbsEntity extends Immutable<MutableDbsEntity> { }