import { DbConceptName, Dbd, EventAccessTypeId, EventTypeId, Immutable } from '@me-interfaces';


interface MutableDbdEventType extends Dbd {

	readonly _concept: DbConceptName.EventType,

	eventTypeId: EventTypeId,
	fullName: string,
	name: string,
	toolNameLabelKey: string,
	org: string,
	attendedBy: string,
	eventAccessTypeId: EventAccessTypeId,
}


export interface DbdEventType extends Immutable<MutableDbdEventType> { }