import { SiteAccessProgram } from '@me-interfaces';

export interface SiteAccess {
	siteId: number,
	enabled: boolean,
	permissions: {
		canPrepareContacts: boolean,
		isSiteStaffOrBetter: boolean,
		isDirector: boolean,
		isManager: boolean,
		isIntern: boolean,
	},
	programs: SiteAccessProgram[],
}