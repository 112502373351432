import { AppAreaAccess, AppAreaName } from "@me-interfaces";

/**
 * Defines an identifier extracted from the url.
 */
export interface AppAreaIdentifier<ID extends number | string> {

	/**
	 * The textual name of the app area. Each one corresponds to a service of the same name.
	 */
	areaName: AppAreaName,

	/**
	 * A unique key for this app area but *not* for this identified instance of this
	 * area. To create a unique key for the instance, concatenate the id to the end
	 * of the areaCacheKey. e.g. instanceCacheKey = `${areaCacheKey}/${id}`;
	 */
	areaCacheKey: string,

	/**
	 * The identifier itself which may be either a number or string
	 */
	id: ID,


	/**
	 * If the url segment directly after the app area id matches one of the subAreas
	 * AND the next segment exists, the next segment will be parsed as a numeric id
	 * and this subArea will show those name and id values.
	 */
	subPath?: {
		name: string,
		id: number,
	}
}



export interface AppAreaIdentifierAndAccess<IDENTIFIER extends AppAreaIdentifier<string | number>, ACCESS extends AppAreaAccess> {
	id: IDENTIFIER,
	access: ACCESS,
}