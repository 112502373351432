import { PhoneTypeId } from "@me-interfaces";

export interface MinimalVolunteerApplication {
	siteIds: number[],
	foundOutHow: string,
	applyMentor: boolean,
	applyReader: boolean,
	applyInterviewer: boolean,
	applyClassSpeaker: boolean,
	applyOfficeHours: boolean,
	applyWorkshops: boolean,
	applyEventhelper: boolean,
	langEN: number,
	langES: number,
	bioEN: string,
	bioES: string,
	company?: string,
	title?: string,
	phone?: string,
	phoneTypeId?: PhoneTypeId,
	preferences?: string,
	inUS: boolean,
	address: string,
	zipCode: string,
	// facebook?: string,
	// twitter?: string,
	// website?: string,
	// linkedin?: string,
}
