/**
 * @deprecated Use the DataService instead
 */
export interface DdVenueRow {
	venueId: number,
	officialName: string,
	displayedName: string,
	address: string,
	zipId: number,
	placeId: string,
	latitude: number,
	longitude: number,
	isActive: boolean,
	website?: string,
	createdUTC: number,
	createdByPersonId: number,
	updatedUTC: number,
	updatedByPersonId: number,
	publicNotes?: string,
	internalNotes?: string,
}