import { DbsApplication, DbsPitchContest } from "@me-interfaces";

export interface PicAreaRawData {

	pitch: DbsPitchContest,
	applications: DbsApplication[],
	teams: { teamId: number, companyId: number, presentType: string }[],
	contestJudgementCounts: {
		picTeamId: number,
		numJudgements: number,
	}[],

	feedback?: {
		companyId: number,
		applicantId: number,

		readers: {
			reviewerId: number,
			originalFeedback: string,
			feedbackForEforAll: string,
			polishedFeedback: string,
		}[],
		judgements?: {
			reviewerId: number,
			originalFeedback: string,
			feedbackForEforAll: string,
			polishedFeedback: string,
		}[]
	}[],


	//TODO: Add some stuff

}