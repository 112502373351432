import { AwardKindId, DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAwardKind extends Dbd {

	readonly _concept: DbConceptName.AwardKind,

	awardKindId: AwardKindId,
	name: string,
}


export interface DbdAwardKind extends Immutable<MutableDbdAwardKind> { }