
/**
 * Defines what the user will be able to do or see for this application.
 * This interface is FRONT FACING so do not put anything sensitive here.
 */
export interface ApplicationAccess {
	/** If the user can update the whole application in the current tool */
	canUpdate: boolean,

	/** If the user is a participant and can currently update their information */
	canUpdateOwnParticipantDetails: boolean,

	/** Whether the applicant demographic fields are visible. The user is the applicant or has person.canEditDemographic flag */
	canSeeApplicantDemographics: boolean,

	/** Whether questions marked as eforall eyes only are visible */
	canSeeInternalQuestions: boolean,

	/** Whether questions marked as staff only are visible */
	canSeeStaffOnlyQuestions: boolean,

	/** Whether the tell us about your team section is shown */
	canSeeTeamDetails: boolean,

	/** Whether the user can edit the application on behalf of the applicant */
	userIsElevated: boolean,

	/** Whether the applicant can submit the application */
	applicantCanSubmit?: boolean,

	/** Whether the applicant can withdraw the application */
	applicantCanWithdraw?: boolean,

	/** Whether the applicant can reopen the application */
	applicantCanReopen?: boolean,
}