export interface ContactUpdateDetails {
	personId: number,
	firstName: string,
	middleInit: string,
	lastName: string,
	phone: string,
	inUS: boolean,
	address: string,
	zipId?: number,
	isDeceased: boolean,
	hasOptOutDemographics: boolean,
	isCrmUser: boolean,
	isEForAllAdmin: boolean,
	isTechAdmin: boolean,
}