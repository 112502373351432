import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccSession extends Dba {

	readonly _concept: DbConceptName.AccSession,

	accSessionId: number,
	eventId: number,
}


export interface DbaAccSession extends Immutable<MutableDbaAccSession> { }