import { Dbc } from "@me-interfaces";


export interface DbcVolunteerApplication extends Dbc {
	volunteerApplicationId: number,
	personId: number,
	company: string,
	title: string,
	foundOutHow: string,
	applyMentor: boolean,
	applyReader: boolean,
	applyInterviewer: boolean,
	applyClassSpeaker: boolean,
	applyOfficeHours: boolean,
	applyWorkshops: boolean,
	applyEventhelper: boolean,
	createdUTC: number,
	preferences: string,
}