import { DbcAccTeamGoal, DbcAccTeamGoalComment, IndustryId } from "@me-interfaces";

// this will be used for both, acc and user area
export interface AccTeamGoalsAndAccess {
	acc: {
		accId: number,
		name: string,
		siteName: string,
	},
	team: {
		name: string,
		industryId: IndustryId,
		accTeamId: number,
	},
	goals: DbcAccTeamGoal[],
	comments: {
		comment: DbcAccTeamGoalComment,
		commenterName: string,
	}[],
	access: {
		canAddGoal: boolean,
		canEditGoal: boolean,
		canAddComment: boolean,
	},
	quarterlyGoalFlags: QuarterlyGoalFlagsByQuarter,

}


// this will be used for both, acc and user area
export interface QuarterlyGoalFlags {
	goalsEnabled: boolean,
	progressEnabled: boolean
}

export interface QuarterlyGoalFlagsByQuarter {
	1: QuarterlyGoalFlags,
	2: QuarterlyGoalFlags,
	3: QuarterlyGoalFlags,
	4: QuarterlyGoalFlags,
}