import { DbConceptName, Dbs, DbsPerson, Immutable, Program, Site } from "@me-interfaces";


interface MutableDbsSiteProgram extends Dbs {

	readonly _concept: DbConceptName.SiteProgram,

	readonly siteProgramId: number,
	readonly siteId: number,
	readonly programId: number,
	readonly managerId: number,
	readonly isActive: boolean,
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
	readonly altManagerId: number | undefined,
}


interface MutableSiteProgram extends MutableDbsSiteProgram {
	site: Site,
	manager: DbsPerson,
	altManager: DbsPerson | undefined,
	updatedByPersonName: string,
	program: Program,
}


export interface DbsSiteProgram extends Immutable<MutableDbsSiteProgram> { }
export interface SiteProgram extends Immutable<MutableSiteProgram> { }