import { Dba, DbConceptName, Immutable } from '@me-interfaces';

export type EnrollmentType = 'Self' | 'Enrolled';

interface MutableDbaCourse extends Dba {

	readonly _concept: DbConceptName.Course,

	courseId: number,
	languageId: number,
	title: string,
	description: string,
	topicId: number,
	enrollmentType: EnrollmentType,
	createdUTC: number,
	updatedUTC: number,
}


export interface DbaCourse extends Immutable<MutableDbaCourse> { }