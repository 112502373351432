import { LabelKey } from '@me-interfaces';

export type LinkType = 'href' | 'routerLink' | 'callback' | 'actionClick-event' | 'no-action';

export interface AppLink<T = void> {

	/**
	 * A value of type string will be displayed as-is, not translated.
	 * A value of type LabelKey will be translated.
	 */
	label: string | LabelKey,
	linkType: LinkType,
	url?: string | any, //Should only be string or SafeUrl
	download?: string,
	callback?: () => Promise<T>,
	callbackResult?: T,
}