import { DbsPerson, DbsPitchContest, PicPresentType } from "@me-interfaces";

export interface SiteAreaPitch {
    pitch: DbsPitchContest,
    applicationCounts: {
        [applicationStatusId: number]: number,
    },
    teams: {
        picTeamId: number,
        presentType: PicPresentType,
    }[]
    manager: DbsPerson
}