import { DbConceptName, Dbd, Immutable, QuestionsTypeId } from '@me-interfaces';


interface MutableDbdQuestionsType extends Dbd {

	readonly _concept: DbConceptName.QuestionsType,

	questionsTypeId: QuestionsTypeId,
	name: string,
}


export interface DbdQuestionsType extends Immutable<MutableDbdQuestionsType> { }