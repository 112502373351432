import { LabelKey } from '@me-interfaces';
import { GridColumnType } from './grid-column-type';
import { GridValueStyle } from './grid-value-style';


export interface GridColumn<RowT, ValueT = unknown> {

	/**
	 * A numeric order that is used to sort the display order of the columns.
	 * Default value is 200 which will place the column just before the
	 * experience's lower-priority columns and after the high-priority ones.
	 */
	order?: number,


	/**
	 * The property on the row object used to extract the value
	 */
	field: string,


	/**
	 * Optionally pass a function which will be called for each row.
	 * If the return value is a string, the returned string will be a title.
	 */
	tooltip?: (row: RowT) => string | undefined,

	/**
	 * Optionally pass a function which will be called for each row.
	 * If the return value is true, the data will be displayed with
	 * red strikethrough.
	 */
	strikethrough?:  (row: RowT) => boolean,


	/**
	 * The data type of the column which defines how the grid cell should display the value
	 */
	type: GridColumnType,


	/**
	 * A keyOrLiteral value that will be passed to LabelService.getKeyOrLiteral()
	 */
	header: string | LabelKey,

	/**
	 * A keyOrLiteral value that will be passed to LabelService.getKeyOrLiteral()
	 */
	headerTooltip?: string | LabelKey,

	/**
	 * The width of the column in pixels
	 */
	width: number,


	/**
	 * If true, the column will not be included in the downloads. Default: false
	 */
	noDownload?: boolean,

	/**
	 * If true, the column will be initially hidden but can be made visible with the column header menu. Default: false
	 */
	hidden?: boolean,


	/**
	 * If true, the column header will not include the vertical-elipses menu
	 */
	noColumnMenu?: boolean,


	/**
	 * An optional function to calculate the text displayed for the value
	 */
	render?: (v: ValueT) => string,


	/**
	 * If the style is number or dollars, the count of fractional digits to show to show. Default: 0 
	 */
	fractionDigits?: number;


	/**
	 * If the style is boolean, the strings that will be displayed. Default: [ {key: 'False'}, {key: 'True'} ]
	 */
	booleanDisplay?: [string | LabelKey, string | LabelKey];



	/**
	 * Displays a colored icon(gauge) based on the thresholds
	 */
	gaugeThresholds?: [number, number, number, number],

	/**
	 * Displays a colored progress bar based on the thresholds
	 */
	progressBarThresholds?: [number, number],


	/**
	 * An optional function to determine a set of styles based on the value.
	 * The type of the parameter is the type given for GridSetup<T> and should
	 * align with the GridColumnType used. If the function is not defined then
	 * the value will be rendered with plain, unstyled text.
	 */
	valueStyle?: (row: RowT, field: string) => GridValueStyle;
}