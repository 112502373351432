import { Icon } from "./icon";
import { IconHideTextAt } from "./icon-hide-text-at";


export interface IconContext {

	/** The enumerated icon value */
	icon?: Icon;

	/** A FontAwesome icon including the style prefix and possibly a color class */
	iconClass: string;

	/** Text to display right of the icon or as a tooltip. */
	text: string;

	/** Breakpoint in which the text should change from right of icon to hidden but as a tooltip */
	hideText?: IconHideTextAt;

	/** Optional flag indicating if a red flag should be rendered next to the item */
	hasRedFlag?: boolean,

	/** If provided, this text will be displayed as small text under the icon context text. */
	subText?: string,
}