import { DbConceptName, Dbd, DecidingRoleId, Immutable } from '@me-interfaces';


interface MutableDbdDecidingRole extends Dbd {

	readonly _concept: DbConceptName.DecidingRole,

	decidingRoleId: DecidingRoleId,
	name: string,
}


export interface DbdDecidingRole extends Immutable<MutableDbdDecidingRole> { }