import { DbaScheduleTemplate, DbaScheduleTemplateSession, DbaScheduleTemplateSessionTopic } from "@me-interfaces";

export interface TechAreaRawData {
	missingDemographics: {
		site: string,
		year: number,
		season: string,
		personId: number,
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		demographicsGiven: 'P' | 'N' | 'O',
	}[],

	goalCounts: {
		accTeamId: number,
		numGoals: number,
	}[],

	scheduleTemplates: DbaScheduleTemplate[],
	scheduleTemplateSessions: DbaScheduleTemplateSession[],
	scheduleTemplateSessionTopics: DbaScheduleTemplateSessionTopic[],

}