import { Dba, DbConceptName, Immutable, Person, SevenNums } from '@me-interfaces';


interface MutableDbaAccMatchableMentor extends Dba {

	readonly _concept: DbConceptName.AccMatchableMentor,

	accId: number,
	personId: number,

	useCount: number,

	mon: number,
	tue: number,
	wed: number,
	thur: number,
	fri: number,
	sat: number,
	sun: number,

	matchingEventId: number,

	pitch: string,
}


export interface DbaAccMatchableMentor extends Immutable<MutableDbaAccMatchableMentor> { }

/**
 * A person that is avaiable to be matched as a mentor in a specific accelerator.
 * It includes their use count and schedule availabilty.
 */
export interface AccMatchableMentor extends DbaAccMatchableMentor {
	schedule: SevenNums,
	person: Person,
}