import { DbConceptName, Dbd, Immutable, IndustryId } from '@me-interfaces';


interface MutableDbdIndustry extends Dbd {

	readonly _concept: DbConceptName.Industry,

	industryId: IndustryId,
	name: string,
}


export interface DbdIndustry extends Immutable<MutableDbdIndustry> { }