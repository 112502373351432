import { AccStageId, AccTeamGoalsAndAccess, DbaCourse, DbaCourseSection, DbaCourseSegment, DbsAgreementVersion, DbsEvent, PicPresentType, PicStageId } from "@me-interfaces";

export interface UserAreaRawData {

	personId: number,
	profile: UserProfile,


	userAgreementTypes: UserAgreementType[],


	entrepreneur: {
		accs: PublicAreaEntrepreneurAcc[],
	},

	mentor: {
		teams: {
			siteName: string,
			teamGoals: AccTeamGoalsAndAccess,
			// mentorPersonIds: number[], // list of mentor/coach for the team
		}[],
	},

	applications: {
		applicationId: number,
		applicationStatusId: number,
		accId: number,
		picId: number,
	}[],

	companies: UserCompany[],

	accTeams: UserAccTeam[],

	picTeams: UserPicTeam[],

	agreementSignatures: {
		agreementSignatureId: number,
		agreementVersionId: number,
		updatedUTC: number,
	}[],

	eligiblePrograms: {
		siteId: number,
		programId: number,
		accId: number,
		picId: number,
		languageId: number,
	}[],

	userSites: UserSite[],
	userAccelerators: UserAccelerator[],
	userPitchContests: UserPitch[],

	courses: DbaCourse[],
	courseSections: DbaCourseSection[],
	courseSegments: DbaCourseSegment[],

}

/**
 * Site interface for the frontend. 
 */
export interface UserSite {
	siteId: number,
	name: string,
	code: string,
	stateCode: string,
	code6: string,
	hidden: boolean,
	isActive: boolean,
	emailEn: string,
	emailEs: string,
}



/**
 * Accelerator interface for the frontend. 
 */
export interface UserAccelerator {
	accId: number,
	name: string,
	accStageId: AccStageId,
	languageId: number,
	siteProgramId: number,
	programName: string,
	longName: string,
	year: number,
	accLanguageSeasonId: number,
	siteId: number,
	directorName: string,
	directorEmail: string,
	managerName: string,
	managerEmail: string,
	canceled: boolean,
	applStartUTC: number,
	applDuration: number,
	classStartUTC: number,
}


/**
 * Pitch interface for the frontend. 
 */
export interface UserPitch {
	picId: number,
	picStageId: PicStageId,
	siteProgramId: number,
	programName: string,
	languageId: number,
	name: string,
	longName: string,
	siteId: number,
	directorName: string,
	directorEmail: string,
	managerName: string,
	managerEmail: string,
	applStartUTC: number,
	applDuration: number,
	classStartUTC: number,
}


export interface UserProfile {
	address: string,
	zipId: number,
	inUS: boolean
}

export interface UserCompany {
	companyId: number,
	companyName: string,
	companyTypeId: number,
	industryId: number
	title: string,
}

export interface UserAccTeam {
	accTeamId: number,
	accId: number,
	droppedOutWeek: number,
	applicationId: number,
	receivedAwards: number,
}


export interface UserPicTeam {
	picTeamId: number,
	picId: number,
	presentType: PicPresentType,
	receivedAwards: number,
	applicationId: number,
}

export interface UserAgreementType {
	agreementTypeId: number,
	nameEN: string,
	nameES: string,
	latestAgreementVersionId: number,
	slug: string,
	version: DbsAgreementVersion,
}


export interface PublicAreaEntrepreneurAcc {
	accId: number,
	accName: string,
	siteName: string,
	teamGoals: AccTeamGoalsAndAccess[],
	accSessions: {
		accSessionId: number,
		venue: {
			displayedName: string,
			officialName: string,
			placeId: string,
			website: string,
		},
		sessionAttendanceTaken: boolean,
		topicIds: number[],
		accId: number,
		event: DbsEvent,
		accSessionSurveyResponseId: number,
		attendance: 'Present' | 'Absent',
	}[],
}