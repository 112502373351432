export interface CompanyBasicInfo {
	companyId: number,
	longName: string,
	shortName: string,
	companyTypeId: number,
	phone: string,
	phoneTypeId: number,
	status: string,
	offerings: string,
	industryId: number,
	profileUpdate: boolean,
}