import { DbcZip, LabelsData, SimplePerson } from '@me-interfaces';

export interface AppSessionPreloadedData {

	/** Person objects of the people used in domain data */
	people: SimplePerson[],

	/** As defined in the config table (ConfigId.DirectorOfProgramsPersonId) */
	directorOfPrograms: SimplePerson,

	/** Zip objects of the zips used in domain data */
	zips: DbcZip[],

	/** Preloaded labels for the languageId that was passed to session.start */
	labels: LabelsData,

	/** Load DbsPerson Objects with crm access, facilitator-approved tag or current facilitator */
	staffMembers: SimplePerson[],
}