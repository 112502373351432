export interface ProgramInstance {
	type: 'Accelerator' | 'Pitch Contest',
	name: string,
	programInstanceId: number,
	stageName: string,
	stageId: number,
	siteCode: string,
	siteName: string,
	language: 'English' | 'Spanish',
	unsubmittedApplicants: number,
	submittedOrHigherApplicants: number,
	totalTeams: number,
	applStartUTC: number,
}