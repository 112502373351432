import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from '@me-interfaces';

export type EforAllSubArea =
	'awaitingApproval' |
	'communitySelection' |
	'missingDemographics' |
	'programEditor';
	

export interface EforAllAreaAccess extends AppAreaAccess {
	root: AppAreaAccessValue,
	sub: {
		awaitingApproval: AppSubAreaAccessValue, //access/admin/communities/eforall/awaiting-approval
		communitySelection: AppSubAreaAccessValue, //access/admin/communities/eforall/community-selection
		missingDemographics: AppSubAreaAccessValue,
		programEditor: AppSubAreaAccessValue, //access/admin/communities/eforall/program-editor
	},
}