import { DbConceptName, Dbs, DbsAgreementType, DbsAgreementVersion, DbsPerson, Immutable, Person } from '@me-interfaces';


interface MutableDbsAgreementSignature extends Dbs {

	readonly _concept: DbConceptName.AgreementSignature,

	readonly agreementSignatureId: number,
	readonly personId: number,
	readonly agreementVersionId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}

interface MutableAgreementSignature extends MutableDbsAgreementSignature {
	person: DbsPerson,
	agreementVersion: DbsAgreementVersion,
	agreementType: DbsAgreementType,
	updatedByPersonName: string,
}


export interface DbsAgreementSignature extends Immutable<MutableDbsAgreementSignature> { }
export interface AgreementSignature extends Immutable<MutableAgreementSignature> { }