import { Dbc, DbsWebLink, ImmutableObject } from '@me-interfaces';
import { Address } from "../../db-concepts";


export interface DbcPublicPerson extends Dbc {
	readonly personId: number,
	readonly firstName: string,
	readonly middleInit: string,
	readonly lastName: string,
	readonly demographicsGiven: string,
	readonly _name: string,
	//TODO: Should phone really be here?
	readonly phone: string,
	readonly phoneTypeId: number,
	readonly _email: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}

export interface PublicPersonForDialog extends DbcPublicPerson {
	readonly phone: string,
	readonly phoneTypeId: number,
	readonly email: string,
	readonly address?: Address,
	readonly webLinks: readonly ImmutableObject<DbsWebLink>[],
}