import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsApplicationParticipant extends Dbs {

	readonly _concept: DbConceptName.ApplicationParticipant,

	readonly applicationParticipantId: number,
	readonly applicationId: number,
	readonly personId: number,
	readonly title: string,
	readonly homeAddress: string,
	readonly homeZipId: number,
	readonly homeInUS: 'N' | 'Y',
	readonly employmentStatusId: number | undefined,
	readonly headOfHousehold: 'N' | 'Y' | undefined,
	readonly householdIncome: number,
	readonly doingItFullTime: 'N' | 'Y' | undefined,
	readonly isCompleted: 'N' | 'Y',
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly lastYearCompensation: number,
	readonly updatedByPersonId: number,
}


export interface DbsApplicationParticipant extends Immutable<MutableDbsApplicationParticipant> { }