export interface MenuItem {
	label: string,
	label_collapsed?: string,
	routerLink?: string,
	url?: string,
	target?: string,
	icon: string,
	subMenu?: MenuItem[],
	separator?: boolean,
	isAdminMenu?: boolean,
}