import { AccAreaService, AccPreAcceleratorPageService, cleanMatchingHints } from '@ACC-area';
import { Component } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, MatchingHint } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { Icon, IconContext, getIconContextForAccTeam, getIconContextForPerson } from '@me-shared-parts/UI-common';
import { Observable, map, switchMap } from 'rxjs';
import { MatchingHintsEditDialog } from './dialog/matching-hints-edit.dialog';


export interface HintRow {
	hintIndex: number,
	kind: 'Team' | 'Mentor',
	importance: 'Nice to Have' | 'Strongly Desired',
	accTeamIdOrPersonId: number,
	teamOrMentor: IconContext,
	relationship: 'With' | 'Not With',
	personId: number,
	overlapCount: number,
	mentor: IconContext,
	hint: MatchingHint,
}


@Component({
	selector: 'acc-matching-hints-view-part',
	templateUrl: './acc-matching-hints-view.part.html',
})
export class AccMatchingHintsViewPart extends DestroyablePart {

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.MatchingMentors);

	private canEdit: boolean;
	rows$: Observable<HintRow[]> = this.accAreaService.mentorMatching.hints$.pipe(

		//
		// Get the mentors from singletons so we can use their names
		//
		switchMap(async hints => {

			const accTeamIds = [
				...hints.filter(hint => hint.kind.startsWith('T')).map(hint => hint.id1),
			];

			const mentorIds = [
				...hints.filter(hint => hint.kind.startsWith('M')).map(hint => hint.id1),
				...hints.map(hint => hint.id2),
			];

			const accTeamMap = await this.ds.admin.accTeam.getManyPackagesAsMap(accTeamIds);
			const mentorMap = await this.ds.admin.person.getManyPackagesAsMap(mentorIds);

			return { hints, accTeamMap, mentorMap };
		}),

		//
		// Build the grid rows
		//
		map(({ hints, accTeamMap, mentorMap }) => {
			return hints.map((hint, idx) => {

				let kind: 'Team' | 'Mentor';
				let teamOrMentor: IconContext;

				if (hint.kind.startsWith('T')) {
					kind = 'Team';
					teamOrMentor = getIconContextForAccTeam(accTeamMap[hint.id1]);
				}
				else {
					kind = 'Mentor';
					teamOrMentor = getIconContextForPerson(mentorMap[hint.id1]);
				}

				return {
					hintIndex: idx + 1,
					kind,
					importance: hint.critical ? 'Strongly Desired' : 'Nice to Have',
					accTeamIdOrPersonId: hint.id1,
					teamOrMentor,
					relationship: hint.kind == 'M+M' ? 'With' : 'Not With',
					personId: hint.id2,
					overlapCount: hint.overlaps,
					mentor: getIconContextForPerson(mentorMap[hint.id2]),
					hint,
				}
			});
		})
	);


	gridSetup$ = this.accessAtStage$
		.pipe(map(accessAtStage => {

			this.canEdit = accessAtStage.access == 'Write';

			const gridSetup: GridSetup<HintRow> = {

				experience: 'none',
				size: {
					fitTo: 'PAGE-TABS-MAIN-TAB',
					heightMultiplier: 1,
					shrinkBy: 0,
					layout$: this.pageService.layout$,
					viewSelector: true,
				},
				rowSingularName: "Matching Hint",
				rowPluralName: "Matching Hints",
				rowKey: "hintIndex",
				stateKey: AccMatchingHintsViewPart.name,
				canAdd: this.canEdit,
				canRefresh: false,
				canDownload: true,
				columnsToAdd: [
					{ field: "kind", header: "Kind", width: 70, type: GridColumnType.text, hidden: true },
					{ field: "importance", header: "Importance", width: 108, type: GridColumnType.text },
					{ field: "accTeamIdOrPersonId", header: "accTeamIdOrPersonId", width: 150, type: GridColumnType.number, hidden: true },
					{ field: "teamOrMentor", header: "Team or Mentor", width: 170, type: GridColumnType.iconContext },
					{ field: "relationship", header: "Relationship", width: 120, type: GridColumnType.text },
					{ field: "personId", header: "personId", width: 90, type: GridColumnType.number, hidden: true },
					{ field: "mentor", header: "Mentor", width: 170, type: GridColumnType.iconContext },
					{ field: "overlapCount", header: "Overlapping Meetings", width: 150, type: GridColumnType.text },

				],
				actions: [
					{ key: 'edit-hint', icon: Icon.action_edit, label: 'Edit Hint', enabled: true },
					{ key: 'remove-hint', icon: Icon.action_delete, label: 'Remove Hint', enabled: true },
				],
				actionEnabler: this.gridActionEnabler.bind(this),
				initialState: {
					sort: [{ field: 'mentorName1', dir: 'asc' }],
				},
			};

			return gridSetup;

		}));



	constructor(
		private ds: DataService,
		public accAreaService: AccAreaService,
		private pageService: AccPreAcceleratorPageService,
		private dialogService: DialogService,
	) {
		super();
	}


	gridActionEnabler(action: GridAction, rows: HintRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'edit-hint' || action.key == 'remove-hint') {
			if (!this.canEdit) return false;
		}
		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: HintRow[] }) {

		const row = action.rows[0];
		const actionKey = action.actionKey;

		if (actionKey == ADDROW_GRID_ACTION_KEY) {
			if (!this.canEdit) return;
			const action: DialogAction<MatchingHint> = await this.ds.dialogService.showCustom(MatchingHintsEditDialog, {
				data: {
					isAdd: true,
					kind: undefined,
					id1: undefined,
					id2: undefined,
					critical: undefined,
					overlaps: undefined,
				}

			}, 450, 350);

			const id = action?.id;
			if (id == 'add') {
				if (action.callbackResult) await this.saveHint(action.callbackResult);
			}
		}
		else if (actionKey == 'edit-hint' || actionKey == DBLCLICK_GRID_ACTION_KEY) {
			if (!this.canEdit) return;

			const action: DialogAction<MatchingHint> = await this.ds.dialogService.showCustom(MatchingHintsEditDialog, {
				data: {
					isAdd: false,
					kind: row.hint.kind,
					id1: row.accTeamIdOrPersonId,
					id2: row.personId,
					critical: row.hint.critical,
					overlaps: row.hint.overlaps,
				}
			}, 450, 350);

			const id = action?.id;
			if (id == 'update') {
				if (action.callbackResult) await this.saveHint(action.callbackResult);
			}
		}

		else if (action.actionKey == 'remove-hint') {
			if (!this.canEdit) return;

			const yes = await this.dialogService.confirm(`Are you sure you want to remove hint?`, 280, 150, 'Remove', 'Cancel');
			if (yes) {
				let hints = this.accAreaService.mentorMatching.hints$.value || [];
				hints = hints.reduce((a, hint) => {
					if (hint.id1 != row.accTeamIdOrPersonId || hint.id2 != row.personId || hint.kind != row.hint.kind) {
						a.push(hint);
					}
					return a;
				}, []);

				hints = cleanMatchingHints(hints);
				await this.accAreaService.mentorMatching.actions.setHints({ hints: hints.length ? JSON.stringify(hints) : '' });
			}
		}
	}


	private async saveHint(hint: MatchingHint) {
		if (!this.canEdit) return;

		let hints = this.accAreaService.mentorMatching.hints$.value || [];
		hints.push(hint);
		hints = cleanMatchingHints(hints);
		await this.accAreaService.mentorMatching.actions.setHints({ hints: hints.length ? JSON.stringify(hints) : '' });
	}
}