import { DialogAction } from "./dialog-action";

/**
 * Whenever something happens with a dialog, such as open, close, or another action
 * then the DialogService.activity event is emitted with an instance of this interface.
 */
export interface DialogActivity<T = any> {

	/**
	 * The DialogService.activity event streams dialog activities from any dialog launched from
	 * any component. Set the instanceIdentifier to uniquely identify a dialog instance when
	 * observing that stream.
	 * 
	 * Simple dialogs do not need to set instanceIdentifier
	 */
	instanceIdentifier: string,

	/**
	 * Similar to the launchedFromClass, the dialogClass is a unique string that represents the
	 * class name of the dialog do the activity stream can be filtered. e.g. dialogClass: SomeDialog.name
	 */
	dialogClass: string,

	/**
	 * General description of what activity has happened
	 */
	activity: 'Open' | 'Close' | 'Action',

	action?: DialogAction,
	value?: T,
}