/**
 * Describes the styling of a cell value.
 */
export interface GridValueStyle {

	/** An optional FontAwesome icon. e.g. 'far fa-user' Default: no icon. */
	icon?: string,

	/** An optional color string. Default: 'Black' */
	iconColor?: 'Black' | 'Red' | 'Blue' | 'Green',

	/** Text may optionally be given a background color or hidden altogether. */
	textStyle?: 'Normal' | 'Error' | 'Warning' | 'Highlight' | 'Success' | 'Hidden',
}