import { DbConceptName, Dbs, DbsPerson, DbsSite, Immutable } from "@me-interfaces";


interface MutableDbsCouncilMember extends Dbs {

	readonly _concept: DbConceptName.CouncilMember,

	readonly councilMemberId: number,
	readonly siteId: number,
	readonly personId: number,
	readonly startUTC: number,
	readonly endUTC: number,
	readonly createdUTC: number,
	readonly createdByPersonId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


interface MutableCouncilMember extends MutableDbsCouncilMember {
	person: DbsPerson,
	site: DbsSite,
	updatedByPersonName: string,
}


export interface DbsCouncilMember extends Immutable<MutableDbsCouncilMember> { }
export interface CouncilMember extends Immutable<MutableCouncilMember> { }