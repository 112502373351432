import { CaptionedLayout, DbsPerson, DbcProgramIntern, DbcSiteIntern, ProgramIntern } from '@me-interfaces';

export interface SiteIntern {
	siteInternId: number,
	siteIntern: DbcSiteIntern,
	person: DbsPerson,
	programs: {
		programInterns: DbcProgramIntern[],
		programId: number,
		siteProgramId: number,
	}[]
}

export interface SiteConfigProgram {
	programId: number,
	siteProgramId: number,
	programInterns: ProgramIntern[],
}

export interface SiteConfig {
	interns: SiteIntern[],
	eforallStaff: CaptionedLayout[],
	programs: SiteConfigProgram[],
}