/**
 * This interface is used to get the list of currently accepting application programs
 */
export interface ApplicationProgramInstance {
	programName: string,
	accId: number,
	picId: number,
	languageId: number,
	programInstanceName: string,
	programId: number,
	siteId: number,
	siteName: string,
	description: string,
	link: string,
}