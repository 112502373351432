export interface SearchContactResult {
	personId: number,
	avatar: string,
	firstName: string,
	middleInit: string,
	lastName: string,
	home: string | undefined,
	emails: string[],
	phone: string | undefined,
	work?: { title: string, company: string },
	isMentor: boolean,
	strongMatch: boolean,
}