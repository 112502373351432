import { ExplorableConceptName } from '@me-interfaces';

/**
 * A common set of name and id properties for
 * usage across disparate data types.
 */
export interface Package {

	/**
	 * An aliased form of the data type's name. For example,
	 * with AccTeam.application.company._name is also exposed as accTeam.name
	 */
	readonly name: string,

	/**
	 * An aliased form of the data type's id. For example,
	 * with accelerator.accId is also exposed as accelerator.id
	 */
	readonly id: number,

}


/**
 * An extension of the Package class that provides extra values used
 * for explorable packages. All packages that are displayed in the
 * explorer or search must implement this interface.
 */
export interface ExplorablePackage<CONCEPT extends ExplorableConceptName = ExplorableConceptName> extends Package {

	readonly _concept: CONCEPT,

	/**
	 * Whether a red flag icon should be displayed with this package. It may be a
	 * direct red flag (e.g. on a person or company) or indirect (e.g. a team whose
	 * company or has a member that has a red flag)
	 */
	readonly hasRedFlag: boolean,

	/**
	 * A calculated routerNav link to navigate to the data type's specific instance.
	 */
	readonly appUrl: string,

	/**
	 * An alternative name to be displayed in the explorer droplist.
	 */
	readonly explorerName: string,
}