import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdLanguage extends Dbd {

	readonly _concept: DbConceptName.Language,

	languageId: number,
	name: string,
}


export interface DbdLanguage extends Immutable<MutableDbdLanguage> { }